<template>
  <!-- <div v-if="this.$store.state.modelCodeX != 'single-company'" class="container mt-2">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="'/' + this.$store.state.langCodeX">{{
            this.$store.getters.getWordLocale("txt_anasayfa")
          }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="'/' + this.$store.state.langCodeX + '/companies'">{{
            this.$store.getters.getWordLocale("txt_firma_listesi")
          }}</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$store.getters.getWordLocale("txt_firma_detayi") }}
        </li>
      </ol>
    </nav>
  </div> -->

  <component :is="optionComponent" />
</template>

<script>
import { defineAsyncComponent } from 'vue'
const Theme1 = defineAsyncComponent(() => import('../components/theme1/CompanyDetails.vue'))

const Theme2 = defineAsyncComponent(() => import('../components/theme2/CompanyDetails.vue'))

const Theme3 = defineAsyncComponent(() => import('../components/theme3/CompanyDetails.vue'))

const Theme4 = defineAsyncComponent(() => import('../components/theme4/CompanyDetails.vue'))

const Theme5 = defineAsyncComponent(() => import('../components/theme5/CompanyDetails.vue'))

const Theme6 = defineAsyncComponent(() => import('../components/theme6/CompanyDetails.vue'))

import PagePropertiesMixin from '../mixins/pagePropertiesMixin'
import { mapState } from 'vuex'
import helper from './helper.js'

export default {
  mixins: [PagePropertiesMixin],
  computed: mapState({
    detailsTitleX: (state) => state.detailsTitleX,
    optionComponent() {
      if (this.$store.state.modelCodeX == 'default') {
        return Theme1
      }

      if (this.$store.state.modelCodeX == 'ek-web') {
        return Theme2
      }

      if (this.$store.state.modelCodeX == 'single-company') {
        return Theme3
      }

      if (this.$store.state.modelCodeX == 'multi-company') {
        return Theme4
      }

      if (this.$store.state.modelCodeX == 'single-company-1') {
        return Theme5
      }

      if (this.$store.state.modelCodeX == 'multi-company-1') {
        return Theme6
      }

      return ''
    }
  }),
  watch: {
    detailsTitleX() {
      this.pageTitle = this.$store.state.detailsTitleX
      this.pageMeta = [
        {
          name: 'description',
          content: this.$store.state.detailsDescriptionX
        },
        {
          name: 'keywords',
          content: this.$store.state.detailsKeywordsX
        }
      ]
    }
  },
  data() {
    return {
      pageTitle: '',
      pageMeta: []
    }
  },
  components: {},
  mounted() {
    this.pageTitle = this.$store.state.detailsTitleX
    this.pageMeta = [
      {
        name: 'description',
        content: this.$store.state.detailsDescriptionX
      },
      {
        name: 'keywords',
        content: this.$store.state.detailsKeywordsX
      }
    ]
  },
  created() {
    setTimeout(() => {
      if (this.$store.state.googleAnalyticsCodeX != '') {
        helper.appendGoogleAnalyticsScript(
          this.$store.state.googleAnalyticsCodeX,
          this.$store.state.langCodeX,
          this.$store.state.domainNameX,
          this.$store.state.currentUrlX,
          this.$store.state.detailsTitleX,
          this.$store.state.membershipNameX,
          this.$store.state.companyNameX,
          this.$store.state.singleCompanySecondaryIdX,
          this.$store.state.isVipX
        )
      }
    }, 1000)
  },
  methods: {}
}
</script>

<style>
.card {
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
</style>
