<template>
  <component :is="optionComponent" :product-content="$store.state.domainContentX.ProductContent" />
</template>

<script>
import { defineAsyncComponent } from 'vue'
const Theme1 = defineAsyncComponent(() => import('../components/theme1/Products.vue'))

const Theme2 = defineAsyncComponent(() => import('../components/theme2/Products.vue'))

const Theme3 = defineAsyncComponent(() => import('../components/theme3/Products.vue'))

const Theme4 = defineAsyncComponent(() => import('../components/theme4/Products.vue'))

const Theme5 = defineAsyncComponent(() => import('../components/theme5/Products.vue'))

const Theme6 = defineAsyncComponent(() => import('../components/theme6/Products.vue'))

import PagePropertiesMixin from '../mixins/pagePropertiesMixin'
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import helper from './helper.js'
export default {
  components: {},
  mixins: [PagePropertiesMixin],
  props: {
    productTitle: String,
    productDescription: String,
    productKeywords: String
  },
  setup(props) {
    const siteData = reactive({
      title: props.productTitle,
      description: props.productDescription,
      keywords: props.productKeywords
    })

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        }
      ]
    })
  },
  data() {
    return {
      pageTitle: '',
      pageMeta: []
    }
  },
  computed: {
    optionComponent() {
      if (this.$store.state.modelCodeX == 'default') {
        return Theme1
      }

      if (this.$store.state.modelCodeX == 'ek-web') {
        return Theme2
      }
      if (this.$store.state.modelCodeX == 'single-company') {
        return Theme3
      }

      if (this.$store.state.modelCodeX == 'multi-company') {
        return Theme4
      }

      if (this.$store.state.modelCodeX == 'single-company-1') {
        return Theme5
      }

      if (this.$store.state.modelCodeX == 'multi-company-1') {
        return Theme6
      }

      return ''
    }
  },
  created() {
    if (this.$store.state.googleAnalyticsCodeX != '') {
      helper.appendGoogleAnalyticsScript(
        this.$store.state.googleAnalyticsCodeX,
        this.$store.state.langCodeX,
        this.$store.state.domainNameX,
        this.$store.state.currentUrlX,
        this.$store.state.homeTitleX,
        this.$store.state.membershipNameX,
        this.$store.state.companyNameX,
        this.$store.state.singleCompanySecondaryIdX,
        this.$store.state.isVipX
      )
    }
  },
  methods: {}
}
</script>

<style>
.card {
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.seo-section {
  border: 1px solid #ced4da;
  border-radius: 4px;
  color: #202428;
  background-color: #ffffff;
  padding: 8px;
}

.seo-section h1 {
  font-size: 25px;
}

.seo-section h2 {
  font-size: 18px;
}

.seo-section h3 {
  font-size: 14px;
}
</style>
