export default {
  data: () => {
    return {
      pageTitle: '',
      pageMeta: []
    }
  },
  watch: {
    pageTitle(title) {
      if (title) {
        document.title = title
      }
    },
    pageMeta(meta) {
      if (Array.isArray(meta) && meta.length) {
        document.head.querySelectorAll('[data-vue-router-controlled]').forEach((element) => {
          document.head.removeChild(element)
        })

        meta
          .map((tagDef) => {
            const tag = document.createElement('meta')
            Object.keys(tagDef).forEach((key) => {
              tag.setAttribute(key, tagDef[key])
            })
            tag.setAttribute('data-vue-router-controlled', '')
            return tag
          })
          .forEach((tag) => document.head.appendChild(tag))
      }
    }
  }
}
