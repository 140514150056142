import $ from 'jquery'

var setOp = {
  appendToHead(title, description, keywords) {
    $(`meta[property='keywords']`).remove()
    $(`meta[property='description']`).remove()

    $(`head`).append(`<meta property="keywords" content="${keywords}">`)
    $(`head`).append(`<meta property="description" content="${description}">`)
    $(`title`).html(title)
  },
  getLangCode() {
    var langCode = window.location.pathname.split('/')[1]
    if (langCode == '' || langCode == undefined || langCode == null) {
      return 'en'
    }

    return langCode
  },
  changeFavicon(url) {
    var link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = url
  },
  setCoverImage() {
    $(`#app`).attr(`style`, `background-image: url('')`)
  },
  appendTraffics(allTraffic, specialTraffic) {
    $(`body`).append(allTraffic)
    $(`body`).append(specialTraffic)
  },
  appendJivoScript(jivoScript, currentLanguage) {
    jivoScript = jivoScript.replace(`@currentLanguage`, `${currentLanguage}`)
    $(`body`).append(jivoScript)
  },
  appendGoogleAnalyticsScript(
    googleAnalyticsCode,
    currentLanguage,
    domainName,
    currentUrl,
    pageTitle,
    membershipName = '',
    companyName = '',
    companyId = '',
    isVip = ''
  ) {
    $('#google-analytics').empty()
    googleAnalyticsCode = googleAnalyticsCode.replace(`{CurrentLanguage}`, `${currentLanguage}`)
    googleAnalyticsCode = googleAnalyticsCode.replace(`{DomainName}`, `${domainName}`)
    googleAnalyticsCode = googleAnalyticsCode.replace(`{CurrentUrl}`, `${currentUrl}`)
    googleAnalyticsCode = googleAnalyticsCode.replace(
      `{PageTitle}`,
      `${pageTitle} | Forie Network yazılım`
    )
    googleAnalyticsCode = googleAnalyticsCode.replace(`{MembershipName}`, `${membershipName}`)
    googleAnalyticsCode = googleAnalyticsCode.replace(`{CompanyName}`, `${companyName}`)
    googleAnalyticsCode = googleAnalyticsCode.replace(`{CompanyId}`, `${companyId}`)
    googleAnalyticsCode = googleAnalyticsCode.replace(`{IsVip}`, `${isVip}`)

    $(`#google-analytics`).append(googleAnalyticsCode)
  },
  generateGuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    )
  },
  setCookie(name, value, days) {
    var expires = ''
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
  },
  getCookie(name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  },
  getPageUrlParameter(param, url) {
    var n_url = new URL(url)
    var p_value = n_url.searchParams.get(param)

    return p_value
  },
  clearUrlParameters() {
    var clearUrl = window.location.href.replace(window.location.search, '')
    setTimeout(() => {
      window.history.pushState({}, '', clearUrl)
    }, 1000)
  },
  hideLoader() {
    setTimeout(() => {
      $(`body`).removeClass(`vld-shown`)
      $(`.vld-overlay`).parent().remove()
    }, 500)
  }
}

export default setOp
