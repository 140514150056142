<template>
  <component :is="optionComponent" />
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Theme3 = defineAsyncComponent(() => import('../components/theme3/500.vue'))

const Theme4 = defineAsyncComponent(() => import('../components/theme4/500.vue'))

const Theme5 = defineAsyncComponent(() => import('../components/theme5/500.vue'))

const Theme6 = defineAsyncComponent(() => import('../components/theme6/500.vue'))

import PagePropertiesMixin from '../mixins/pagePropertiesMixin'
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
export default {
  components: {},
  mixins: [PagePropertiesMixin],
  props: {
    productTitle: String,
    productDescription: String,
    productKeywords: String
  },
  setup(props) {
    const siteData = reactive({
      title: props.productTitle,
      description: props.productDescription,
      keywords: props.productKeywords
    })

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        }
      ]
    })
  },
  data() {
    return {
      pageTitle: '',
      pageMeta: []
    }
  },
  computed: {
    optionComponent() {
      if (this.$store.state.modelCodeX == 'single-company') {
        return Theme3
      }
      if (this.$store.state.modelCodeX == 'multi-company') {
        return Theme4
      }
      if (this.$store.state.modelCodeX == 'single-company-1') {
        return Theme5
      }
      if (this.$store.state.modelCodeX == 'multi-company-1') {
        return Theme6
      }
      return ''
    }
  },
  created() {},
  methods: {}
}
</script>
