import { createStore } from 'vuex'

export default createStore({
  state: {
    userCountryCodeX: '',
    companiesUrlX: 'https://www.turkishexporter.com.tr/en/companies/',
    singleCompanyProductIdX: 0,
    singleCompanySearchX: '',
    userDashboardUrlX: '',
    cdnUrlX: 'https://cdn.forie.com',
    companyManagementUrlX: '',
    messageCenterUrlX: '',
    requestCenterUrlX: '',
    leadsListDataX: [],
    companiesListDataX: [],
    singleCompanyUrlNameX: '',
    singleCompanyUrlCountryX: '',
    homePageAboutUsImageV: '',
    homePageImageV: '',
    aboutUsImageV: '',
    ourProductsImageV: '',
    contactUsImageV: '',
    footerLogoV: '',
    fullCountriesX: [],
    singleCompanyFullSegmentsX: [],
    singleCompanySegmentNamesX: [],
    singleCompanyFullCategoriesX: [],
    singleCompanyFullProductsX: [],
    singleCompanyFullInfoX: [],
    singleCompanyLogoX: [],
    singleCompanyIdX: [],
    singleCompanySecondaryIdX: [],
    singleCompanyDetailsX: [],
    domainCustomVariablesX: [],
    membershipNameX: '',
    companyNameX: '',
    isVipX: '',

    parentDomainX: '',

    loginUrlX: 'https://login.forie.com',
    signUpUrlX: 'https://login.forie.com/en/Account/FreeJoin',

    myPageUrlX: 'https://forie.com',
    apiUrlX: 'https://api.forie.com',

    systemLogoX: '',
    isLoginPageX: true,
    currentUrlX: '',
    isMountedDoneX: false,

    userLoggedInX: false,
    deviceIdX: '',
    userNameX: '',
    userEmailX: '',
    userPhoneX: '',
    userPasswordX: '',

    publicAccessTokenX: 'ABF5F262-2438-4B90-BCC1-D9529037A60B',
    apiAccessTokenX: '',

    jivoScriptX: '',
    googleAnalyticsCodeX: '',

    allTrafficX: '',
    specialTrafficX: '',

    mainCategoriesX: [],
    domainCompanyCategoriesX: [],
    domainLeadCategoriesX: [],

    headerLogoX: '',
    featuredCompaniesX: [],
    featuredLeadsX: [],
    defaultLogoX: '',

    defaultDomainX: '',
    domainSystemX: null,
    detailsTitleX: '',
    detailsDescriptionX: '',
    detailsKeywordsX: '',

    allowAdsX: false,
    langCodeX: 'en',
    modelCodeX: '',
    headerItemsX: [],
    domainContentX: [],
    homeTitleX: '',
    homeDescriptionX: '',
    homeKeywordsX: '',

    companyTitleX: '',
    companyDescriptionX: '',
    companyKeywordsX: '',

    companyDetailsTitleX: '',
    companyDetailsDescriptionX: '',
    companyDetailsKeywordsX: '',

    leadTitleX: '',
    leadDescriptionX: '',
    leadKeywordsX: '',

    leadDetailsTitleX: '',
    leadDetailsDescriptionX: '',
    leadDetailsKeywordsX: '',

    productTitleX: '',
    productDescriptionX: '',
    productKeywordsX: '',

    productDetailsTitleX: '',
    productDetailsDescriptionX: '',
    productDetailsKeywordsX: '',

    domainNameX: '',

    isFirstLoadX: true,
    isSearchChangedX: false,
    searchInputValueX: '',
    LocalizationsEn: [],
    LocalizationsTr: [],
    LocalizationsFr: [],
    LocalizationsAr: [],
    LocalizationsRu: [],
    LocalizationsEs: [],
    LocalizationsPt: [],
    domainLanguages: [],

    DomainsHomeTitleX: [],

    languagesNamesX: [
      {
        code: 'en',
        name: 'English'
      },
      {
        code: 'tr',
        name: 'Türkçe'
      },
      {
        code: 'fr',
        name: 'Français'
      },
      {
        code: 'pt',
        name: 'Português'
      },
      {
        code: 'es',
        name: 'Español'
      },
      {
        code: 'ru',
        name: 'Pусский'
      },
      {
        code: 'ar',
        name: 'العربية'
      }
    ]
  },
  getters: {
    getWordLocale: (state) => (word) => {
      if (state.langCodeX == 'en') {
        if (state.LocalizationsEn[word] == undefined) {
          return word
        }

        return state.LocalizationsEn[word]
      } else if (state.langCodeX == 'tr') {
        if (state.LocalizationsTr[word] == undefined) {
          if (state.LocalizationsEn[word] != undefined) {
            return state.LocalizationsEn[word]
          }
          return word
        }

        return state.LocalizationsTr[word]
      } else if (state.langCodeX == 'fr') {
        if (state.LocalizationsFr[word] == undefined) {
          if (state.LocalizationsEn[word] != undefined) {
            return state.LocalizationsEn[word]
          }
          return word
        }

        return state.LocalizationsFr[word]
      } else if (state.langCodeX == 'ar') {
        if (state.LocalizationsAr[word] == undefined) {
          if (state.LocalizationsEn[word] != undefined) {
            return state.LocalizationsEn[word]
          }
          return word
        }

        return state.LocalizationsAr[word]
      } else if (state.langCodeX == 'ru') {
        if (state.LocalizationsRu[word] == undefined) {
          if (state.LocalizationsEn[word] != undefined) {
            return state.LocalizationsEn[word]
          }
          return word
        }

        return state.LocalizationsRu[word]
      } else if (state.langCodeX == 'pt') {
        if (state.LocalizationsPt[word] == undefined) {
          if (state.LocalizationsEn[word] != undefined) {
            return state.LocalizationsEn[word]
          }
          return word
        }

        return state.LocalizationsPt[word]
      } else if (state.langCodeX == 'es') {
        if (state.LocalizationsEs[word] == undefined) {
          if (state.LocalizationsEn[word] != undefined) {
            return state.LocalizationsEn[word]
          }
          return word
        }

        return state.LocalizationsEs[word]
      } else {
        if (state.LocalizationsEn[word] == undefined) {
          return word
        }

        return state.LocalizationsEn[word]
      }
    },
    replaceTurkishCharcters: () => (text) => {
      return text
        .replace('ö', 'o')
        .replace('ü', 'u')
        .replace('ç', 'c')
        .replace('ı', 'i')
        .replace('ğ', 'g')
        .replace('ş', 's')
    },
    truncateString: (state) => (obj) => {
      if (state.langCodeX) {
        //
      }
      if (obj.text.length > obj.length) {
        return obj.text.substring(0, obj.length)
      }
      return obj.text
    },
    // toTitles: (s) => {
    //     return s.replace(/\w\S*/g, function (t) {
    //       return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
    //     });
    //   }
    getDomainTitle: (state) => (domain) => {
      //if (state.langCodeX == "en") {
      if (state.DomainsHomeTitleX[domain] == undefined) {
        return domain
      }

      return state.DomainsHomeTitleX[domain]
      //}
    }
  },
  mutations: {},
  actions: {}
})
