<template>
  <component :is="optionComponent" />
</template>

<script>
import { defineAsyncComponent } from 'vue'
const Theme1 = defineAsyncComponent(() => import('../components/theme1/Footer.vue'))

const Theme2 = defineAsyncComponent(() => import('../components/theme2/Footer.vue'))

const Theme3 = defineAsyncComponent(() => import('../components/theme3/Footer.vue'))

const Theme4 = defineAsyncComponent(() => import('../components/theme4/Footer.vue'))

const Theme5 = defineAsyncComponent(() => import('../components/theme5/Footer.vue'))

const Theme6 = defineAsyncComponent(() => import('../components/theme6/Footer.vue'))

export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    optionComponent() {
      if (this.$store.state.modelCodeX == 'default') {
        return Theme1
      }

      if (this.$store.state.modelCodeX == 'ek-web') {
        return Theme2
      }

      if (this.$store.state.modelCodeX == 'single-company') {
        return Theme3
      }

      if (this.$store.state.modelCodeX == 'multi-company') {
        return Theme4
      }

      if (this.$store.state.modelCodeX == 'single-company-1') {
        return Theme5
      }

      if (this.$store.state.modelCodeX == 'multi-company-1') {
        return Theme6
      }
      return ''
    }
  },
  methods: {}
}
</script>

<style></style>
