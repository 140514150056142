<template>
  <component :is="optionComponent" />
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Theme1 = defineAsyncComponent(() => import('../components/theme1/Home.vue'))

const Theme2 = defineAsyncComponent(() => import('../components/theme2/Home.vue'))

const Theme3 = defineAsyncComponent(() => import('../components/theme3/Home.vue'))

const Theme4 = defineAsyncComponent(() => import('../components/theme4/Home.vue'))

const Theme5 = defineAsyncComponent(() => import('../components/theme5/Home.vue'))

const Theme6 = defineAsyncComponent(() => import('../components/theme6/Home.vue'))

import PagePropertiesMixin from '../mixins/pagePropertiesMixin'
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import helper from './helper.js'
export default {
  components: {},
  mixins: [PagePropertiesMixin],
  props: {
    homeTitle: String,
    homeDescription: String,
    homeKeywords: String
  },
  setup(props) {
    const siteData = reactive({
      title: props.homeTitle,
      description: props.homeDescription,
      keywords: props.homeKeywords
    })

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        }
      ]
    })
  },
  data() {
    return {
      pageTitle: 'MMMMM',
      pageMeta: []
    }
  },
  computed: {
    optionComponent() {
      if (this.$store.state.modelCodeX == 'default') {
        return Theme1
      }

      if (this.$store.state.modelCodeX == 'ek-web') {
        return Theme2
      }

      if (this.$store.state.modelCodeX == 'single-company') {
        return Theme3
      }

      if (this.$store.state.modelCodeX == 'multi-company') {
        return Theme4
      }

      if (this.$store.state.modelCodeX == 'single-company-1') {
        return Theme5
      }

      if (this.$store.state.modelCodeX == 'multi-company-1') {
        return Theme6
      }
      return ''
    }
  },
  mounted() {
    if (this.$store.state.googleAnalyticsCodeX != '') {
      helper.appendGoogleAnalyticsScript(
        this.$store.state.googleAnalyticsCodeX,
        this.$store.state.langCodeX,
        this.$store.state.domainNameX,
        this.$store.state.currentUrlX,
        this.$store.state.homeTitleX,
        this.$store.state.membershipNameX,
        this.$store.state.companyNameX,
        this.$store.state.singleCompanySecondaryIdX,
        this.$store.state.isVipX
      )
    }
  },
  methods: {}
}
</script>

<style>
.normal-flag {
  margin: 0 !important;
  margin-left: -14px !important;
}
</style>
