<template>
  <component :is="optionComponent" :product-content="$store.state.domainContentX.ProductContent" />
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Theme3 = defineAsyncComponent(() => import('../components/theme3/ProductDetails.vue'))

const Theme4 = defineAsyncComponent(() => import('../components/theme4/ProductDetails.vue'))

const Theme5 = defineAsyncComponent(() => import('../components/theme5/ProductDetails.vue'))

const Theme6 = defineAsyncComponent(() => import('../components/theme6/ProductDetails.vue'))

import PagePropertiesMixin from '../mixins/pagePropertiesMixin'
import { mapState } from 'vuex'
import helper from './helper.js'
export default {
  mixins: [PagePropertiesMixin],
  computed: mapState({
    detailsTitleX: (state) => state.detailsTitleX,
    optionComponent() {
      if (this.$store.state.modelCodeX == 'single-company') {
        return Theme3
      }

      if (this.$store.state.modelCodeX == 'multi-company') {
        return Theme4
      }

      if (this.$store.state.modelCodeX == 'single-company-1') {
        return Theme5
      }

      if (this.$store.state.modelCodeX == 'multi-company-1') {
        return Theme6
      }
    }
  }),
  watch: {
    detailsTitleX() {
      this.pageTitle = this.$store.state.detailsTitleX
      this.pageMeta = [
        {
          name: 'description',
          content: this.$store.state.detailsDescriptionX
        },
        {
          name: 'keywords',
          content: this.$store.state.detailsKeywordsX
        }
      ]
    }
  },
  data() {
    return {
      pageTitle: '',
      pageMeta: []
    }
  },
  created() {
    setTimeout(() => {
      if (this.$store.state.googleAnalyticsCodeX != '') {
        helper.appendGoogleAnalyticsScript(
          this.$store.state.googleAnalyticsCodeX,
          this.$store.state.langCodeX,
          this.$store.state.domainNameX,
          this.$store.state.currentUrlX,
          this.$store.state.detailsTitleX,
          this.$store.state.membershipNameX,
          this.$store.state.companyNameX,
          this.$store.state.singleCompanySecondaryIdX,
          this.$store.state.isVipX
        )
      }
    }, 1000)
  },
  methods: {}
}
</script>
