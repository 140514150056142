import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Companies from '../views/Companies.vue'
import CompanyDetails from '../views/CompanyDetails.vue'
import Leads from '../views/Leads.vue'
import LeadDetails from '../views/LeadDetails.vue'
import Products from '../views/Products.vue'
import NotFound from '../views/NotFound.vue'
import Login from '../views/Login.vue'
import Contact from '../views/Contact.vue'
import ProductDetails from '../views/ProductDetails.vue'
import ServerError from '../views/500.vue'
import ServiceUnavailable from '../views/503.vue'

const routes = [
  {
    path: '/',
    name: '',
    component: Home
  },
  {
    path: '/:langCode',
    name: '',
    component: Home
  },
  {
    path: '/:langCode/companies',
    name: '',
    component: Companies
  },
  {
    path: '/:langCode/leads',
    name: '',
    component: Leads
  },
  {
    path: '/:langCode/products',
    name: '',
    component: Products
  },
  {
    path: '/:langCode/:country/export-products/:companyName',
    name: '',
    component: Products
  },
  {
    path: '/:langCode/company/:id',
    name: '',
    component: CompanyDetails
  },
  {
    path: '/:langCode/lead/:id',
    name: '',
    component: LeadDetails
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/:langCode/about',
    name: 'About',
    component: CompanyDetails
  },
  {
    path: '/:langCode/500',
    name: 'Server Error',
    component: ServerError
  },
  {
    path: '/:langCode/503',
    name: 'Service Unavailable',
    component: ServiceUnavailable
  },
  {
    path: '/:langCode/contact',
    name: '',
    component: Contact
  },
  {
    path: '/:langCode/products/:id',
    name: '',
    component: ProductDetails
  },
  {
    path: '/:langCode/:id/:companyName/:countryName/export-products',
    name: '',
    component: ProductDetails
  },
  {
    path: '/:langCode/login',
    name: 'Login',
    component: Login
  },
  {
    component: NotFound,
    path: '/:pathMatch(.*)*',
    name: 'Page Not Found!'
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  document.title = to.name
  next()
})

export default router
