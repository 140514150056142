<template>
  <div class="container">
    <!-- <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">{{
            this.$store.getters.getWordLocale("txt_anasayfa")
          }}</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$store.getters.getWordLocale("txt_firma_listesi") }}
        </li>
      </ol>
    </nav>
    <div class="container seo-section">
      <h1>{{ this.$store.state.companyTitleX }}</h1>
      <h2>{{ this.$store.state.companyDescriptionX == undefined ? "" :  this.$store.state.companyDescriptionX}}</h2>
      <h3 v-show="this.$store.state.companyKeywordsX.length > 0">{{ this.$store.state.companyKeywordsX.length > 0 ? this.$store.state.companyKeywordsX.split(",").join(", ") : "" }}</h3>
    </div> -->
    <!-- <div
      v-show="this.$store.state.allowAdsX"
      id="adsgoeshere"
      style="padding-top: 60px; text-align: center"
      v-html="adsenseContent"
    ></div> -->
  </div>

  <component :is="optionComponent" />
</template>

<script>
import { defineAsyncComponent } from 'vue'
const Theme1 = defineAsyncComponent(() => import('../components/theme1/Companies.vue'))

const Theme2 = defineAsyncComponent(() => import('../components/theme2/Companies.vue'))

const Theme4 = defineAsyncComponent(() => import('../components/theme4/Companies.vue'))

const Theme6 = defineAsyncComponent(() => import('../components/theme6/Companies.vue'))

import PagePropertiesMixin from '../mixins/pagePropertiesMixin'
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import helper from './helper.js'
export default {
  components: {},
  mixins: [PagePropertiesMixin],
  props: {
    companyTitle: String,
    companyDescription: String,
    companyKeywords: String
  },
  setup(props) {
    const siteData = reactive({
      title: props.companyTitle,
      description: props.companyDescription,
      keywords: props.companyKeywords
    })

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        }
      ]
    })
  },
  data() {
    return {
      adsenseContent: '',
      pageTitle: '',
      pageMeta: []
    }
  },
  computed: {
    optionComponent() {
      if (this.$store.state.modelCodeX == 'default') {
        return Theme1
      }

      if (this.$store.state.modelCodeX == 'ek-web') {
        return Theme2
      }

      if (this.$store.state.modelCodeX == 'multi-company') {
        return Theme4
      }

      if (this.$store.state.modelCodeX == 'multi-company-1') {
        return Theme6
      }

      return ''
    }
  },
  mounted() {
    if (this.$store.state.googleAnalyticsCodeX != '') {
      helper.appendGoogleAnalyticsScript(
        this.$store.state.googleAnalyticsCodeX,
        this.$store.state.langCodeX,
        this.$store.state.domainNameX,
        this.$store.state.currentUrlX,
        this.$store.state.homeTitleX,
        this.$store.state.membershipNameX,
        this.$store.state.companyNameX,
        this.$store.state.singleCompanySecondaryIdX,
        this.$store.state.isVipX
      )
    }
    // this.adsenseContent = document.getElementById(
    //   "divadsensedisplaynone"
    // ).innerHTML;
    // this.pageTitle = "Firma Listesi";
    // this.pageMeta = [
    //   {
    //     name: "description",
    //     content: `Generator Turkey Verified Manufacturers and Turkish Generator Supplier Companies list Turkey`,
    //   },
    //   {
    //     property: "keywords",
    //     content: `Generator, Generator Turkey, Turkish Generator ,  Generator Turkish Companies, Generator in Turkey, Generator Istanbul`,
    //   },
    //   {
    //     property: "site_name",
    //     content: `https://exporters.texpo.net/`,
    //   },
    //   {
    //     property: "url",
    //     content: `https://exporters.texpo.net/`,
    //   },
    // ];
  },
  created() {},
  methods: {}
}
</script>

<style>
.card {
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.seo-section {
  border: 1px solid #ced4da;
  border-radius: 4px;
  color: #202428;
  background-color: #ffffff;
  padding: 8px;
}

.seo-section h1 {
  font-size: 25px;
}

.seo-section h2 {
  font-size: 18px;
}

.seo-section h3 {
  font-size: 14px;
}
</style>
