<template>
  <!-- 
Theme1 ve Theme2 artık kullanılmamaktadır. Ancak reference olarak duracaktır.
Theme3 - tekli firma için. Kodu: single-company
Theme4 - çoklu firma için. Kodu: multi-company
Theme5 - Tekli firma için. Kodu: single-company-1 
Theme6 - çoklu firma için. Kodu: multi-company-1
-->

  <div class="wrapper">
    <Header v-if="isDone == true" />
    <router-view v-if="isDone == true" :allow-ads="$store.state.allowAdsX" :home-title="$store.state.homeTitleX"
      :home-description="$store.state.homeDescriptionX" :home-keywords="$store.state.homeKeywordsX"
      :company-title="$store.state.companyTitleX" :company-description="$store.state.companyDescriptionX"
      :company-keywords="$store.state.companyKeywordsX" :company-details-title="$store.state.companyDetailsTitleX"
      :company-details-description="$store.state.companyDetailsDescriptionX"
      :company-details-keywords="$store.state.companyDetailsKeywordsX" :lead-title="$store.state.leadTitleX"
      :lead-description="$store.state.leadDescriptionX" :lead-keywords="$store.state.leadKeywordsX"
      :lead-details-title="$store.state.leadDetailsTitleX"
      :lead-details-description="$store.state.leadDetailsDescriptionX"
      :lead-details-keywords="$store.state.leadDetailsKeywordsX" :product-title="$store.state.productTitleX"
      :product-description="$store.state.productDescriptionX" :product-keywords="$store.state.productKeywordsX"
      :product-details-title="$store.state.productDetailsTitleX"
      :product-details-description="$store.state.productDetailsDescriptionX"
      :product-details-keywords="$store.state.productDetailsKeywordsX" />
    <Footer v-if="isDone == true" />
  </div>
</template>

<script>
import Header from './views/Header.vue'
import Footer from './views/Footer.vue'
import LocalizationsEn from './assets/Localizations/en.json'
import LocalizationsTr from './assets/Localizations/tr.json'
import LocalizationsFr from './assets/Localizations/fr.json'
import LocalizationsAr from './assets/Localizations/ar.json'
import LocalizationsRu from './assets/Localizations/ru.json'
import LocalizationsPt from './assets/Localizations/pt.json'
import LocalizationsEs from './assets/Localizations/es.json'

import DomainsHomeTitle from './assets/HomeTitle/DomainsHomeTitle_EN.json'

import { mapState } from 'vuex'
import helper from './views/helper'
export default {
  computed: mapState({
    langCodeX: (state) => state.langCodeX
  }),
  watch: {
    $route() {
      var currentUrl = window.location.href
      this.$store.state.currentUrlX = currentUrl

      if (this.$store.state.isSearchChangedX == false) {
        this.$store.state.searchInputValueX = ''
      }

      if (
        (currentUrl.toLowerCase().indexOf('/export-products') == -1 ||
          currentUrl.toLowerCase().indexOf('/export-products/') != -1) &&
        currentUrl.toLowerCase().indexOf('not-found') == -1
      ) {
        this.$store.state.singleCompanySearchX = ''
      }

      if (currentUrl.toLowerCase().indexOf('/login') != -1) {
        this.$store.state.isLoginPageX = true
      } else {
        this.$store.state.isLoginPageX = false
        this.$store.state.currentUrlX = location.protocol + '//' + location.host + location.pathname
      }
      helper.setCoverImage()
    },
    langCodeX() {
      if (this.isDone) {
        var controller = window.location.pathname.split('/')[2]
        var action = window.location.pathname.split('/')[3]

        var url = ''
        if (action == undefined) {
          if (controller == undefined) {
            url = `/${this.$store.state.langCodeX}`
          } else {
            url = `/${this.$store.state.langCodeX}/${controller}`
          }
        } else {
          url = `/${this.$store.state.langCodeX}/${controller}/${action}`
        }

        if (window.location.href.indexOf(`export-products`) != -1) {
          var productsUrl = window.location.pathname.split('/')
          productsUrl[1] = this.$store.state.langCodeX
          if (this.$store.state.singleCompanyIdX > 0) {
            var country =
              this.$store.state.singleCompanyDetailsX.Country.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              ).length > 0
                ? this.$store.state.singleCompanyDetailsX.Country.filter(
                  (q) => q.LangCode == this.$store.state.langCodeX
                )[0].Name
                : this.$store.state.singleCompanyDetailsX.Country.filter(
                  (q) => q.LangCode != this.$store.state.langCodeX
                )[0].Name

            this.$store.state.singleCompanyUrlCountryX = `${country
              .toLowerCase()
              .trim()
              .split(' ')
              .join('-')}`
          }

          window.location.href = productsUrl.join('/')
        } else {
          window.location.href = url
        }
      }
    }
  },
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      hostUrl: '',
      modelVariablesList: [],
      apiVariablesList: [],
      systemSettings: [],
      generalSeoSettings: [],
      modelSeoSettings: [],
      isDone: false,
      themesList: [
        'default',
        'ek-web',
        'single-company',
        'single-company-1',
        'multi-company',
        'multi-company-1'
      ],
      items: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Manage',
          href: '#'
        },
        {
          text: 'Library',
          active: true
        }
      ],
      domainName: '',
      countryName: 'Turkey',
      firstCompanyName: '',
      randomCompanyName: '',
      firstCompanyDescription: '',
      randomCompanyDescription: '',
      firstCompanyKeywords: '',
      randomKeywords1: '',
      randomKeywords5: '',
      companyCountry: '',
      leadTitle: '',
      RandomLeadTitle: '',
      firstLeadKeywords: '',
      randomLeadKeywords1: '',
      randomLeadKeywords5: '',
      leadCountry: '',
      firstProductName: '',
      firstCategoryName: '',
      randomCategoryName1: '',
      randomCategoryName2: '',
      firstLeadCategoryName: '',
      randomLeadCategoryName1: '',
      randomLeadCategoryName2: '',
      firstSubCategoryName: '',
      firstLeadSubCategoryName: ''
    }
  },
  mounted() {
    this.hostUrl = window.location.host
      .replace('http://', '')
      .replace('https://', '')
      .replace('/', '')

    this.domainName = window.location.host // Tema Değiştirme İşlemi

    if (this.domainName.indexOf('localhost') !== -1) {
      this.domainName = 'ekweb.org'
      this.$store.state.apiUrlX = 'http://localhost:49253' // 44315 di orjinalde ozkan
      this.$store.state.myPageUrlX = 'http://localhost:52353'
      this.$store.state.loginUrlX = 'http://localhost:64490'
      this.$store.state.cdnUrlX = 'https://cdn.forie.com'
    }

    var parts = this.domainName.split('.')
    this.$store.state.parentDomainX = parts.slice(-2).join('.')

    this.$store.state.langCodeX = helper.getLangCode()
    this.$store.state.currentUrlX = location.protocol + '//' + location.host + location.pathname

    var isFirstLoad = this.$store.state.isFirstLoadX
    if (isFirstLoad == true) {
      this.getMainCategories()
      this.getDomainConfigurations()
      this.$store.state.isFirstLoadX = false
    }

    this.$store.state.LocalizationsEn = LocalizationsEn
    this.$store.state.LocalizationsTr = LocalizationsTr
    this.$store.state.LocalizationsFr = LocalizationsFr
    this.$store.state.LocalizationsAr = LocalizationsAr
    this.$store.state.LocalizationsRu = LocalizationsRu
    this.$store.state.LocalizationsPt = LocalizationsPt
    this.$store.state.LocalizationsEs = LocalizationsEs

    this.$store.state.DomainsHomeTitleX = DomainsHomeTitle

    //#region Login

    var accessToken = helper.getCookie('fn_access_token')
    if (accessToken == null || accessToken == undefined || accessToken == '') {
      var url = window.location.href
      accessToken = helper.getPageUrlParameter('accessToken', url)
    }

    var murl = window.location.href
    var hasLogoutPar = helper.getPageUrlParameter('logout', murl)
    if (hasLogoutPar) {
      this.logout()
    } else {
      this.$store.state.apiAccessTokenX = accessToken
      if (accessToken != null && accessToken != undefined && accessToken != '') {
        helper.setCookie('fn_access_token', accessToken, 1)
        helper.clearUrlParameters()
        this.getUserDetails()
      } else {
        this.$store.state.isMountedDoneX = true
      }
    }
    // #endregion
  },
  methods: {
    async getDomainConfigurations() {
      this.clearSeoSettings()
      await fetch(
        'https://data.mongodb-api.com/app/forienetwork_app-qolvc/endpoint/getDomainDetails',
        {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            domainName: this.domainName
          })
        }
      )
        .then((res) => res.json())
        .then(
          (data) => (
            (this.$store.state.domainContentX = data),
            (this.$store.state.modelCodeX = data.DomainModel.code),
            (this.$store.state.domainSystemX = parseInt(data.DomainSystem))
          )
        )
        .catch((err) => console.log(err.message))
      if (this.$store.state.domainContentX.CompanyContent != undefined) {
        if (this.$store.state.domainContentX.CompanyContent.SelectedCompanies.length == 1) {
          this.$store.state.singleCompanyIdX = parseInt(
            this.$store.state.domainContentX.CompanyContent.SelectedCompanies[0].id
          )
        }
      }
      // TODO : Theme 6 çalıştırır
      // this.$store.state.modelCodeX = "multi-company-1";

      this.$store.state.domainNameX = this.$store.state.domainContentX.DomainName

      this.$store.state.systemLogoX = `https://cdn.forie.com${this.$store.state.domainContentX.SystemLogo}`

      if (
        this.$store.state.domainContentX.Logo != undefined &&
        this.$store.state.domainContentX.Logo != ''
      ) {
        this.$store.state.systemLogoX = `${this.$store.state.cdnUrlX}${this.$store.state.domainContentX.Logo}`
      }

      this.$store.state.allTrafficX = this.$store.state.domainContentX.AllTraffic
      this.$store.state.specialTrafficX = this.$store.state.domainContentX.SpecialTraffic

      this.$store.state.jivoScriptX = this.$store.state.domainContentX.JivoScript
      this.$store.state.googleAnalyticsCodeX = this.$store.state.domainContentX.GoogleAnalyticsCode

      this.$store.state.domainCompanyCategoriesX =
        this.$store.state.domainContentX.DomainCompanyCategories
      this.$store.state.domainLeadCategoriesX =
        this.$store.state.domainContentX.DomainLeadCategories

      this.$store.state.featuredCompaniesX =
        this.$store.state.domainContentX.FeaturedCompaniesList.filter(
          (q) => q.localizations.length > 0
        )
      this.$store.state.featuredLeadsX = this.$store.state.domainContentX.FeaturedLeadsList

      this.$store.state.defaultDomainX = this.$store.state.domainContentX.DefaultDomain.replace(
        'www.',
        ''
      )

      if (window.location.host.indexOf('localhost') == -1) {
        this.$store.state.loginUrlX = `https://auth.${this.$store.state.parentDomainX}`
        this.$store.state.signUpUrlX = `https://auth.${this.$store.state.parentDomainX}/${this.$store.state.langCodeX}/Account/FreeJoin`

        this.$store.state.myPageUrlX = this.$store.state.domainContentX.DefaultDomain.replace(
          'www.',
          ''
        )
        this.$store.state.myPageUrlX = `https://auth.${this.$store.state.parentDomainX}/mypage/${this.$store.state.langCodeX}/?apiAuthToken=${this.$store.state.apiAccessTokenX}&referer=${this.hostUrl}`

        this.$store.state.companyManagementUrlX = this.$store.state.myPageUrlX
        this.$store.state.messageCenterUrlX = `https://auth.${this.$store.state.parentDomainX}/mypage/${this.$store.state.langCodeX}/inbox?apiAuthToken=${this.$store.state.apiAccessTokenX}&referer=${this.hostUrl}`
        this.$store.state.requestCenterUrlX = `https://auth.${this.$store.state.parentDomainX}/mypage/${this.$store.state.langCodeX}/newsletter?apiAuthToken=${this.$store.state.apiAccessTokenX}&referer=${this.hostUrl}`
        this.$store.state.userDashboardUrlX = `https://auth.${this.$store.state.parentDomainX}/mypage/${this.$store.state.langCodeX}/userdashboard?apiAuthToken=${this.$store.state.apiAccessTokenX}&referer=${this.hostUrl}`

        if (this.themesList.indexOf(this.$store.state.modelCodeX) == -1) {
          this.$store.state.modelCodeX = 'multi-company'
        }
      }

      if (this.$store.state.domainContentX.DefaultDomain.indexOf('turkishexporter') !== -1) {
        this.$store.state.defaultLogoX =
          'https://testcdn.turkishexporter.com.tr/storage/images/general/no-image-te.png'
        this.$store.state.headerLogoX = 'te.png'
        helper.changeFavicon(`https://www.turkishexporter.com.tr/favicon.ico`)
      } else {
        this.$store.state.defaultLogoX =
          'https://testcdn.turkishexporter.com.tr/storage/images/general/no-image.png'
        this.$store.state.headerLogoX = 'forie-logo.png'
        helper.changeFavicon(`https://www.forie.com/favicon.ico`)
      }

      this.$store.state.domainLanguages = this.$store.state.domainContentX.DomainLanguages

      if (
        this.$store.state.domainLanguages.filter((q) => q.LangCode == this.$store.state.langCodeX)
          .length == 0
      ) {
        this.$store.state.langCodeX =
          this.$store.state.domainLanguages.filter((q) => q.LangCode == 'en').length > 0
            ? 'en'
            : this.$store.state.domainLanguages[0].LangCode
      }

      if (this.$store.state.singleCompanyIdX > 0) {
        this.getSystemSettings()
      } else {
        this.getModelSeoSettings()
      }
      if (this.$store.state.modelCodeX == 'default' || this.$store.state.modelCodeX == 'ek-web') {
        this.$store.state.modelCodeX = 'multi-company'
      }

      this.$store.state.allowAdsX = this.$store.state.domainContentX.AllowAds
      if (this.$store.state.domainContentX != [] && this.$store.state.domainContentX != null) {
        this.$store.state.headerItemsX = this.$store.state.domainContentX.FilterContent.filter(
          (q) => parseInt(q.status) == 1
        )
      }

      this.GetApiCustomVariables()
    },
    async getSingleCompanyDetails() {
      await fetch(`https://data.mongodb-api.com/app/forienetwork_app-qolvc/endpoint/getCompany`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          companyId: this.$store.state.singleCompanyIdX
        })
      })
        .then((res) => res.json())
        .then((data) => (this.$store.state.singleCompanyDetailsX = data))
        .catch((err) => console.log(err.message))

      console.log(this.$store.state.singleCompanyDetailsX)

      this.$store.state.membershipNameX = ''
      if (
        this.$store.state.singleCompanyDetailsX.MembershipInfo.Localizations.filter(
          (q) => q.LangCode == 'en'
        )[0] != null
      ) {
        this.$store.state.membershipNameX =
          this.$store.state.singleCompanyDetailsX.MembershipInfo.Localizations.filter(
            (q) => q.LangCode == 'en'
          )[0].Name
      }

      this.$store.state.isVipX = this.$store.state.singleCompanyDetailsX.IsVip

      this.$store.state.singleCompanyLogoX =
        this.$store.state.singleCompanyDetailsX.Logo == null
          ? this.$store.state.defaultLogoX
          : `https://cdn.turkishexporter.com.tr${this.$store.state.singleCompanyDetailsX.Logo}`

      this.$store.state.singleCompanySecondaryIdX =
        this.$store.state.singleCompanyDetailsX.SecondaryId

      if (this.$store.state.singleCompanySecondaryIdX != this.$store.state.singleCompanyIdX) {
        this.$store.state.singleCompanyIdX = this.$store.state.singleCompanySecondaryIdX
      }

      this.$store.state.singleCompanyLogoX = this.$store.state.singleCompanyLogoX.toLowerCase()

      if (
        this.$store.state.singleCompanyLogoX.indexOf(`companies/logo/1`) != -1 ||
        this.$store.state.singleCompanyLogoX.indexOf(`companies/logo/logo`) != -1
      ) {
        this.$store.state.singleCompanyLogoX = this.$store.state.defaultLogoX
      }

      if (
        this.$store.state.domainContentX.Logo != undefined &&
        this.$store.state.domainContentX.Logo != ''
      ) {
        this.$store.state.singleCompanyLogoX = `${this.$store.state.cdnUrlX}${this.$store.state.domainContentX.Logo}`
      }

      this.$store.state.singleCompanyDetailsX.Categories.forEach((element) => {
        var categoryId = parseInt(element.MainCategoryId)
        this.$store.state.singleCompanyFullCategoriesX.push(categoryId)
      })

      this.$store.state.singleCompanyDetailsX.Segments.forEach((element) => {
        var segmentId = parseInt(element)
        this.$store.state.singleCompanyFullSegmentsX.push(segmentId)
      })

      var homeTitle =
        this.$store.state.singleCompanyDetailsX.Localizations.filter(
          (q) => q.LangCode == this.$store.state.langCodeX
        ).length > 0
          ? this.$store.state.singleCompanyDetailsX.Localizations.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          )[0].Name
          : this.$store.state.singleCompanyDetailsX.Localizations.filter(
            (q) => q.LangCode != this.$store.state.langCodeX
          )[0].Name

      var country =
        this.$store.state.singleCompanyDetailsX.Country.filter(
          (q) => q.LangCode == this.$store.state.langCodeX
        ).length > 0
          ? this.$store.state.singleCompanyDetailsX.Country.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          )[0].Name
          : this.$store.state.singleCompanyDetailsX.Country.filter(
            (q) => q.LangCode != this.$store.state.langCodeX
          )[0].Name

      this.$store.state.companyNameX = homeTitle

      this.$store.state.singleCompanyUrlNameX = `${homeTitle
        .toLowerCase()
        .trim()
        .split(' ')
        .join('-')}`

      this.$store.state.singleCompanyUrlNameX = this.$store.state.singleCompanyUrlNameX.replace(
        /[^a-zA-Z0-9,-]/g,
        ''
      )
      this.$store.state.singleCompanyUrlNameX = this.$store.state.singleCompanyUrlNameX.replace(
        /---/g,
        '-'
      )
      this.$store.state.singleCompanyUrlNameX = this.$store.state.singleCompanyUrlNameX.replace(
        /--/g,
        '-'
      )

      this.$store.state.singleCompanyUrlCountryX = `${country
        .toLowerCase()
        .trim()
        .split(' ')
        .join('-')}`

      var productName =
        this.$store.state.singleCompanyDetailsX.Products.length > 0
          ? this.$store.state.singleCompanyDetailsX.Products[0].Localizations.filter(
            (q) =>
              q.LangCode == this.$store.state.langCodeX && q.Name != undefined && q.Name != ''
          ).length > 0
            ? this.$store.state.singleCompanyDetailsX.Products[0].Localizations.filter(
              (q) =>
                q.LangCode == this.$store.state.langCodeX && q.Name != undefined && q.Name != ''
            )[0].Name
            : this.$store.state.singleCompanyDetailsX.Products[0].Localizations.filter(
              (q) =>
                q.LangCode != this.$store.state.langCodeX && q.Name != undefined && q.Name != ''
            ).length > 0
              ? this.$store.state.singleCompanyDetailsX.Products[0].Localizations.filter(
                (q) =>
                  q.LangCode != this.$store.state.langCodeX && q.Name != undefined && q.Name != ''
              )[0].Name
              : ''
          : ''

      this.$store.state.homeTitleX = `${homeTitle} - ${country}`
      this.$store.state.homeTitleX = this.$store.state.homeTitleX.substr(0, 70)

      var companyFirst4Words = homeTitle.split(' ').slice(0, 4).join(' ')
      var homeDescription = this.$store.getters
        .getWordLocale('txt_anasayfa_seo_desc')
        .replace(`{1}`, companyFirst4Words)
        .replace('{2}', productName)
        .replace('{3}', country)

      this.$store.state.homeDescriptionX = homeDescription

      this.$store.state.homeKeywordsX = ''

      this.$store.state.detailsTitleX = `${this.$store.getters.getWordLocale('txt_hakkimizda')} - ${this.$store.state.homeTitleX
        }`.substr(0, 70)

      this.$store.state.detailsDescriptionX = this.$store.state.homeDescriptionX
      this.$store.state.detailsKeywordsX = this.$store.state.homeKeywordsX

      var companyFirst3Words = homeTitle.split(' ').slice(0, 3).join(' ')
      this.$store.state.productTitleX = this.$store.getters
        .getWordLocale('txt_products_seo_title')
        .replace(`{1}`, companyFirst3Words)
        .replace('{2}', country)
        .substr(0, 70)

      this.$store.state.productDescriptionX = this.$store.getters
        .getWordLocale('txt_products_seo_desc')
        .replace(`{1}`, country)
        .replace('{2}', companyFirst3Words)

      this.isDone = true
      //this.getModelSeoSettings();
    },
    async GetApiCustomVariables() {
      var result = []
      await fetch(`${this.$store.state.apiUrlX}/api/v2/home/getCustomVariables`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Public-WebAccessToken': this.$store.state.publicAccessTokenX
        }
      })
        .then((res) => res.json())
        .then((data) => (result = data.responseObject))
        .catch((err) => console.log(err.message))

      this.apiVariablesList = []
      if (result != undefined && result != null && result.length > 0) {
        result.forEach((element) => {
          this.apiVariablesList.push({
            id: element.id,
            code: element.code,
            description: element.description,
            defaultValue: element.defaultValue,
            position: element.position
          })
        })
      }

      this.getModelCustomVariables()
    },
    async getModelCustomVariables() {
      var result = []
      await fetch(
        `https://data.mongodb-api.com/app/forienetwork_app-qolvc/endpoint/getModelCustomVariables`,
        {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            modelCode: this.$store.state.modelCodeX
          })
        }
      )
        .then((res) => res.json())
        .then((data) => (result = data))
        .catch((err) => console.log(err.message))
      this.modelVariablesList = []
      if (
        result != undefined &&
        result.CustomVariables != undefined &&
        result.CustomVariables.length > 0
      ) {
        result.CustomVariables.forEach((element) => {
          var code = element.code
          this.modelVariablesList.push(code)
        })
      }

      this.setDomainVariables()
    },
    setDomainVariables() {
      if (
        this.$store.state.domainContentX != undefined &&
        this.$store.state.domainContentX.DomainCustomVariables != undefined
      ) {
        var domainVariables = this.$store.state.domainContentX.DomainCustomVariables

        domainVariables.forEach((element) => {
          var code = element.Code

          if (
            this.apiVariablesList.filter((q) => q.code == code).length > 0 &&
            this.modelVariablesList.indexOf(code) != -1
          ) {
            var value = this.apiVariablesList.filter((q) => q.code == code)[0].defaultValue

            if (
              element.Localizations.length > 0 &&
              element.Localizations.filter(
                (q) =>
                  q.LangCode == this.$store.state.langCodeX &&
                  q.Value != null &&
                  q.Value != undefined &&
                  q.Value != ''
              ).length > 0
            ) {
              value = element.Localizations.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              )[0].Value
            }

            this.$store.state.domainCustomVariablesX.push({
              code: code,
              value: value
            })
          }
        })

        if (this.$store.state.domainCustomVariablesX.length == 0) {
          this.modelVariablesList.forEach((code) => {
            var value =
              this.apiVariablesList.filter((q) => q.code == code).length > 0
                ? this.apiVariablesList.filter((q) => q.code == code)[0].defaultValue
                : ''

            this.$store.state.domainCustomVariablesX.push({
              code: code,
              value: value
            })
          })
        }

        this.$store.state.homePageImageV =
          this.$store.state.domainCustomVariablesX.filter(
            (q) => q.code == 'HomeImage' && q.value != '' && q.value != undefined
          ).length > 0
            ? this.$store.state.domainCustomVariablesX.filter(
              (q) => q.code == 'HomeImage' && q.value != '' && q.value != undefined
            )[0].value
            : ''

        this.$store.state.homePageAboutUsImageV =
          this.$store.state.domainCustomVariablesX.filter(
            (q) => q.code == 'home-page-about-us-image' && q.value != '' && q.value != undefined
          ).length > 0
            ? this.$store.state.domainCustomVariablesX.filter(
              (q) => q.code == 'home-page-about-us-image' && q.value != '' && q.value != undefined
            )[0].value
            : ''

        this.$store.state.aboutUsImageV =
          this.$store.state.domainCustomVariablesX.filter(
            (q) => q.code == 'about-us-page-image' && q.value != '' && q.value != undefined
          ).length > 0
            ? this.$store.state.domainCustomVariablesX.filter(
              (q) => q.code == 'about-us-page-image' && q.value != '' && q.value != undefined
            )[0].value
            : ''

        this.$store.state.ourProductsImageV =
          this.$store.state.domainCustomVariablesX.filter(
            (q) => q.code == 'our-products-page-image' && q.value != '' && q.value != undefined
          ).length > 0
            ? this.$store.state.domainCustomVariablesX.filter(
              (q) => q.code == 'our-products-page-image' && q.value != '' && q.value != undefined
            )[0].value
            : ''

        this.$store.state.contactUsImageV =
          this.$store.state.domainCustomVariablesX.filter(
            (q) => q.code == 'contact-us-page-image' && q.value != '' && q.value != undefined
          ).length > 0
            ? this.$store.state.domainCustomVariablesX.filter(
              (q) => q.code == 'contact-us-page-image' && q.value != '' && q.value != undefined
            )[0].value
            : ''

        this.$store.state.footerLogoV =
          this.$store.state.domainCustomVariablesX.filter(
            (q) => q.code == 'footer-system-logo' && q.value != '' && q.value != undefined
          ).length > 0
            ? this.$store.state.domainCustomVariablesX.filter(
              (q) => q.code == 'footer-system-logo' && q.value != '' && q.value != undefined
            )[0].value
            : ''
      }
    },
    async getModelSeoSettings() {
      await fetch(
        'https://data.mongodb-api.com/app/forienetwork_app-qolvc/endpoint/getModelSeoSettings',
        {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            modelCode: this.$store.state.modelCodeX
          })
        }
      )
        .then((res) => res.json())
        .then((data) => (this.modelSeoSettings = data))
        .catch((err) => console.log(err.message))
      this.getSystemSettings()
    },
    async getSystemSettings() {
      await fetch(
        'https://data.mongodb-api.com/app/forienetwork_app-qolvc/endpoint/getSystemSettings',
        {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            systemId: this.$store.state.domainSystemX
          })
        }
      )
        .then((res) => res.json())
        .then((data) => (this.systemSettings = data))
        .catch((err) => console.log(err.message))
      if (this.$store.state.singleCompanyIdX > 0) {
        this.processSeoSettings()
      } else {
        this.getGeneralSeoSettings()
      }
    },
    async getGeneralSeoSettings() {
      await fetch(
        'https://data.mongodb-api.com/app/forienetwork_app-qolvc/endpoint/getGeneralSeoSettings',
        {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({})
        }
      )
        .then((res) => res.json())
        .then((data) => (this.generalSeoSettings = data))
        .catch((err) => console.log(err.message))
      this.processSeoSettings()
    },
    processSeoSettings() {
      var temp = []

      // #region Model SEO Region

      // #region System Settings Region
      if (this.systemSettings != null) {
        if (this.$store.state.jivoScriptX == '') {
          this.$store.state.jivoScriptX = this.systemSettings.JivoScript
        }

        if (this.$store.state.googleAnalyticsCodeX == '') {
          this.$store.state.googleAnalyticsCodeX = this.systemSettings.GoogleAnalyticsCode
        }
      }

      //Only get the ceo values and exit.
      if (this.$store.state.singleCompanyIdX > 0) {
        this.getSingleCompanyDetails()
        return 0
      }
      // #endregion

      if (this.modelSeoSettings != null) {
        if (this.$store.state.allTrafficX == '') {
          this.$store.state.allTrafficX = this.modelSeoSettings.AllTraffic
        }

        if (this.$store.state.specialTrafficX == '') {
          this.$store.state.specialTrafficX = this.modelSeoSettings.SpecialTraffic
        }

        if (this.$store.state.jivoScriptX == '') {
          this.$store.state.jivoScriptX = this.modelSeoSettings.JivoScript
        }

        if (this.$store.state.googleAnalyticsCodeX == '') {
          this.$store.state.googleAnalyticsCodeX = this.modelSeoSettings.GoogleAnalyticsCode
        }
        var modelHomeSeo = this.modelSeoSettings.HomeSeo
        var modelCompanySeo = this.modelSeoSettings.CompanySeo
        var modelCompanyDetailsSeo = this.modelSeoSettings.CompanyDetailsSeo
        var modelLeadSeo = this.modelSeoSettings.LeadSeo
        var modelLeadDetailsSeo = this.modelSeoSettings.LeadDetailsSeo
        var modelProductSeo = this.modelSeoSettings.ProductSeo
        var modelProductDetailsSeo = this.modelSeoSettings.ProductDetailsSeo

        var modelHomeTitle = this.$store.getters.getDomainTitle(this.domainName)

        if (modelHomeTitle == this.domainName) {
          modelHomeTitle = modelHomeSeo.filter((q) => q.langCode == this.$store.state.langCodeX)[0]
            .title
        }

        var modelCompanyTitle = modelCompanySeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title
        var modelCompanyDetailsTitle = modelCompanyDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title
        var modelLeadTitle = modelLeadSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title
        var modelLeadDetailsTitle = modelLeadDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title
        var modelProductTitle = modelProductSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title
        var modelProductDetailsTitle = modelProductDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title

        var modelHomeDescription = modelHomeSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description
        var modelCompanyDescription = modelCompanySeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description
        var modelCompanyDetailsDescription = modelCompanyDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description
        var modelLeadDescription = modelLeadSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description
        var modelLeadDetailsDescription = modelLeadDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description
        var modelProductDescription = modelProductSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description
        var modelProductDetailsDescription = modelProductDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description

        var modelHomeKeywords = modelHomeSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords
        var modelCompanyKeywords = modelCompanySeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords
        var modelCompanyDetailsKeywords = modelCompanyDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords
        var modelLeadKeywords = modelLeadSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords
        var modelLeadDetailsKeywords = modelLeadDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords
        var modelProductKeywords = modelProductSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords
        var modelProductDetailsKeywords = modelProductDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords

        if (modelHomeKeywords.length > 0) {
          temp = []
          modelHomeKeywords.forEach((ele) => {
            var text = ele.text
            temp.push(text)
          })
          modelHomeKeywords = temp.join(',')
        }

        if (modelCompanyKeywords.length > 0) {
          temp = []
          modelCompanyKeywords.forEach((ele) => {
            var text = ele.text
            temp.push(text)
          })
          modelCompanyKeywords = temp.join(',')
        }

        if (modelLeadKeywords.length > 0) {
          temp = []
          modelLeadKeywords.forEach((ele) => {
            var text = ele.text
            temp.push(text)
          })
          modelLeadKeywords = temp.join(',')
        }

        if (modelProductKeywords.length > 0) {
          temp = []
          modelProductKeywords.forEach((ele) => {
            var text = ele.text
            temp.push(text)
          })
          modelProductKeywords = temp.join(',')
        }
        temp = []
      }
      // #endregion

      // #region General SEO Region
      if (this.generalSeoSettings != null) {
        if (this.$store.state.allTrafficX == '') {
          this.$store.state.allTrafficX = this.generalSeoSettings.AllTraffic
        }

        if (this.$store.state.specialTrafficX == '') {
          this.$store.state.specialTrafficX = this.generalSeoSettings.SpecialTraffic
        }

        if (this.$store.state.jivoScriptX == '') {
          this.$store.state.jivoScriptX = this.generalSeoSettings.JivoScript
        }

        if (this.$store.state.googleAnalyticsCodeX == '') {
          this.$store.state.googleAnalyticsCodeX = this.generalSeoSettings.GoogleAnalyticsCode
        }

        var generalHomeSeo = this.generalSeoSettings.HomeSeo
        var generalCompanySeo = this.generalSeoSettings.CompanySeo
        var generalCompanyDetailsSeo = this.generalSeoSettings.CompanyDetailsSeo
        var generalLeadSeo = this.generalSeoSettings.LeadSeo
        var generalLeadDetailsSeo = this.generalSeoSettings.LeadDetailsSeo
        var generalProductSeo = this.generalSeoSettings.ProductSeo
        var generalProductDetailsSeo = this.generalSeoSettings.ProductDetailsSeo

        var generalHomeTitle = generalHomeSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title
        var generalCompanyTitle = generalCompanySeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title
        var generalCompanyDetailsTitle = generalCompanyDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title
        var generalLeadTitle = generalLeadSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title
        var generalLeadDetailsTitle = generalLeadDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title
        var generalProductTitle = generalProductSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title
        var generalProductDetailsTitle = generalProductDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].title

        var generalHomeDescription = generalHomeSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description
        var generalCompanyDescription = generalCompanySeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description
        var generalCompanyDetailsDescription = generalCompanyDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description
        var generalLeadDescription = generalLeadSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description
        var generalLeadDetailsDescription = generalLeadDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description
        var generalProductDescription = generalProductSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description
        var generalProductDetailsDescription = generalProductDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].description

        var generalHomeKeywords = generalHomeSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords
        var generalCompanyKeywords = generalCompanySeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords
        var generalCompanyDetailsKeywords = generalCompanyDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords
        var generalLeadKeywords = generalLeadSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords
        var generalLeadDetailsKeywords = generalLeadDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords
        var generalProductKeywords = generalProductSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords
        var generalProductDetailsKeywords = generalProductDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords

        if (generalHomeKeywords.length > 0) {
          temp = []
          generalHomeKeywords.forEach((ele) => {
            var text = ele.text
            temp.push(text)
          })
          generalHomeKeywords = temp.join(',')
        }

        if (generalCompanyKeywords.length > 0) {
          temp = []
          generalCompanyKeywords.forEach((ele) => {
            var text = ele.text
            temp.push(text)
          })
          generalCompanyKeywords = temp.join(',')
        }

        if (generalLeadKeywords.length > 0) {
          temp = []
          generalLeadKeywords.forEach((ele) => {
            var text = ele.text
            temp.push(text)
          })
          generalLeadKeywords = temp.join(',')
        }

        if (generalProductKeywords.length > 0) {
          temp = []
          generalProductKeywords.forEach((ele) => {
            var text = ele.text
            temp.push(text)
          })
          generalProductKeywords = temp.join(',')
        }
        temp = []
      }
      // #endregion

      // #region SEO Region
      if (this.$store.state.domainContentX.HomeSeo != undefined) {
        var homeSeo = this.$store.state.domainContentX.HomeSeo

        var homeTitle = ''
        if (
          homeSeo.filter((q) => q.langCode == this.$store.state.langCodeX && q.title != '').length >
          0
        ) {
          homeTitle = homeSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.title != ''
          )[0].title
        }
        if (homeTitle == '') {
          homeTitle = this.$store.state.homeTitleX
        }
        this.$store.state.homeTitleX = homeTitle

        var homeDescription = ''
        if (
          homeSeo.filter((q) => q.langCode == this.$store.state.langCodeX && q.description != '')
            .length > 0
        ) {
          homeDescription = homeSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.description != ''
          )[0].description
        }
        if (homeDescription == '') {
          homeDescription = this.$store.state.homeDescriptionX
        }
        this.$store.state.homeDescriptionX = homeDescription

        var homeKeywords = []
        homeKeywords = homeSeo.filter((q) => q.langCode == this.$store.state.langCodeX)[0].keywords

        if (homeKeywords == undefined || homeKeywords == [] || homeKeywords.length == 0) {
          homeKeywords = []
        }

        if (homeKeywords.length > 0) {
          temp = []
          homeKeywords.forEach((element) => {
            var text = element.text
            temp.push(text)
          })

          this.$store.state.homeKeywordsX = temp.join(',')
        }
      }

      if (this.$store.state.domainContentX.CompanySeo != undefined) {
        var companySeo = this.$store.state.domainContentX.CompanySeo

        var companyTitle = ''
        if (
          companySeo.filter((q) => q.langCode == this.$store.state.langCodeX && q.title != '')
            .length > 0
        ) {
          companyTitle = companySeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.title != ''
          )[0].title
        }
        if (companyTitle == '') {
          companyTitle = this.$store.state.homeTitleX
        }
        this.$store.state.companyTitleX = companyTitle

        var companyDescription = ''
        if (
          companySeo.filter((q) => q.langCode == this.$store.state.langCodeX && q.description != '')
            .length > 0
        ) {
          companyDescription = companySeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.description != ''
          )[0].description
        }
        if (companyDescription == '') {
          companyDescription = this.$store.state.homeDescriptionX
        }
        this.$store.state.companyDescriptionX = companyDescription

        var companyKeywords = []
        companyKeywords = companySeo.filter((q) => q.langCode == this.$store.state.langCodeX)[0]
          .keywords

        if (companyKeywords == undefined || companyKeywords == [] || companyKeywords.length == 0) {
          companyKeywords = homeKeywords
        }

        if (companyKeywords.length > 0) {
          temp = []
          companyKeywords.forEach((element) => {
            var text = element.text
            temp.push(text)
          })

          this.$store.state.companyKeywordsX = temp.join(',')
        }
      }

      if (this.$store.state.domainContentX.CompanyDetailsSeo != undefined) {
        var companyDetailsSeo = this.$store.state.domainContentX.CompanyDetailsSeo
        var companyDetailsTitle = ''
        if (
          companyDetailsSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.title != ''
          ).length > 0
        ) {
          companyDetailsTitle = companyDetailsSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.title != ''
          )[0].title
        }

        this.$store.state.companyDetailsTitleX = companyDetailsTitle

        var companyDetailsDescription = ''
        if (
          companyDetailsSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.description != ''
          ).length > 0
        ) {
          companyDetailsDescription = companyDetailsSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.description != ''
          )[0].description
        }
        this.$store.state.companyDetailsDescriptionX = companyDetailsDescription

        var companyDetailsKeywords = []
        companyDetailsKeywords = companyDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords

        if (companyDetailsKeywords.length > 0) {
          temp = []
          companyDetailsKeywords.forEach((element) => {
            var text = element.text
            temp.push(text)
          })

          this.$store.state.companyDetailsKeywordsX = temp.join(',')
        }
      }

      if (this.$store.state.domainContentX.LeadSeo != undefined) {
        var leadSeo = this.$store.state.domainContentX.LeadSeo

        var leadTitle = ''
        if (
          leadSeo.filter((q) => q.langCode == this.$store.state.langCodeX && q.title != '').length >
          0
        ) {
          leadTitle = leadSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.title != ''
          )[0].title
        }
        if (leadTitle == '') {
          leadTitle = this.$store.state.homeTitleX
        }
        this.$store.state.leadTitleX = leadTitle

        var leadDescription = ''
        if (
          leadSeo.filter((q) => q.langCode == this.$store.state.langCodeX && q.description != '')
            .length > 0
        ) {
          leadDescription = leadSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.description != ''
          )[0].description
        }
        if (leadDescription == '') {
          leadDescription = this.$store.state.homeDescriptionX
        }
        this.$store.state.leadDescriptionX = leadDescription

        var leadKeywords = []
        leadKeywords = leadSeo.filter((q) => q.langCode == this.$store.state.langCodeX)[0].keywords

        if (leadKeywords == undefined || leadKeywords == [] || leadKeywords.length == 0) {
          leadKeywords = homeKeywords
        }

        if (leadKeywords.length > 0) {
          temp = []
          leadKeywords.forEach((element) => {
            var text = element.text
            temp.push(text)
          })

          this.$store.state.leadKeywordsX = temp.join(',')
        }
      }

      if (this.$store.state.domainContentX.LeadDetailsSeo != undefined) {
        var leadDetailsSeo = this.$store.state.domainContentX.LeadDetailsSeo
        var leadDetailsTitle = ''
        if (
          leadDetailsSeo.filter((q) => q.langCode == this.$store.state.langCodeX && q.title != '')
            .length > 0
        ) {
          leadDetailsTitle = leadDetailsSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.title != ''
          )[0].title
        }
        this.$store.state.leadDetailsTitleX = leadDetailsTitle

        var leadDetailsDescription = ''
        if (
          leadDetailsSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.description != ''
          ).length > 0
        ) {
          leadDetailsDescription = leadDetailsSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.description != ''
          )[0].description
        }
        this.$store.state.leadDetailsDescriptionX = leadDetailsDescription

        var leadDetailsKeywords = []
        leadDetailsKeywords = leadDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords

        if (leadDetailsKeywords.length > 0) {
          temp = []
          leadDetailsKeywords.forEach((element) => {
            var text = element.text
            temp.push(text)
          })

          this.$store.state.leadDetailsKeywordsX = temp.join(',')
        }
      }

      if (this.$store.state.domainContentX.ProductSeo != undefined) {
        var productSeo = this.$store.state.domainContentX.ProductSeo

        var productTitle = ''
        if (
          productSeo.filter((q) => q.langCode == this.$store.state.langCodeX && q.title != '')
            .length > 0
        ) {
          productTitle = productSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.title != ''
          )[0].title
        }
        if (productTitle == '') {
          if (
            productSeo.filter((q) => q.langCode != this.$store.state.langCodeX && q.title != '')
              .length > 0
          ) {
            productTitle = productSeo.filter(
              (q) => q.langCode != this.$store.state.langCodeX && q.title != ''
            )[0].title
          }
        }
        if (productTitle == '') {
          productTitle = this.$store.state.homeTitleX
        }
        this.$store.state.productTitleX = productTitle

        var productDescription = ''
        if (
          productSeo.filter((q) => q.langCode == this.$store.state.langCodeX && q.description != '')
            .length > 0
        ) {
          productDescription = productSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.description != ''
          )[0].description
        }
        if (productDescription == '') {
          if (
            productSeo.filter(
              (q) => q.langCode != this.$store.state.langCodeX && q.description != ''
            ).length > 0
          ) {
            productDescription = productSeo.filter(
              (q) => q.langCode != this.$store.state.langCodeX && q.title != ''
            )[0].description
          }
        }
        if (productDescription == '') {
          productDescription = this.$store.state.homeDescriptionX
        }
        this.$store.state.productDescriptionX = productDescription

        var productKeywords = []
        productKeywords = productSeo.filter((q) => q.langCode == this.$store.state.langCodeX)[0]
          .keywords

        if (productKeywords == undefined || productKeywords == [] || productKeywords.length == 0) {
          productKeywords = homeKeywords
        }

        if (productKeywords.length > 0) {
          temp = []
          productKeywords.forEach((element) => {
            var text = element.text
            temp.push(text)
          })

          this.$store.state.productKeywordsX = temp.join(',')
        }
      }

      if (this.$store.state.domainContentX.ProductDetailsSeo != undefined) {
        var productDetailsSeo = this.$store.state.domainContentX.ProductDetailsSeo

        var productDetailsTitle = ''
        if (
          productDetailsSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.title != ''
          ).length > 0
        ) {
          productDetailsTitle = productDetailsSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.title != ''
          )[0].title
        }
        if (productDetailsTitle == '') {
          if (
            productDetailsSeo.filter(
              (q) => q.langCode != this.$store.state.langCodeX && q.title != ''
            ).length > 0
          ) {
            productDetailsTitle = productDetailsSeo.filter(
              (q) => q.langCode != this.$store.state.langCodeX && q.title != ''
            )[0].title
          }
        }
        this.$store.state.productDetailsTitleX = productDetailsTitle

        var productDetailsDescription = ''
        if (
          productDetailsSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.description != ''
          ).length > 0
        ) {
          productDetailsDescription = productDetailsSeo.filter(
            (q) => q.langCode == this.$store.state.langCodeX && q.description != ''
          )[0].description
        }
        if (productDetailsDescription == '') {
          if (
            productDetailsSeo.filter(
              (q) => q.langCode != this.$store.state.langCodeX && q.description != ''
            ).length > 0
          ) {
            productDetailsDescription = productDetailsSeo.filter(
              (q) => q.langCode != this.$store.state.langCodeX && q.title != ''
            )[0].description
          }
        }
        this.$store.state.productDetailsDescriptionX = productDetailsDescription

        var productDetailsKeywords = []
        productDetailsKeywords = productDetailsSeo.filter(
          (q) => q.langCode == this.$store.state.langCodeX
        )[0].keywords

        if (productDetailsKeywords.length > 0) {
          temp = []
          productDetailsKeywords.forEach((element) => {
            var text = element.text
            temp.push(text)
          })

          this.$store.state.productDetailsKeywordsX = temp.join(',')
        }
      }
      // #endregion

      // #region If no original then model
      if (this.$store.state.homeTitleX == '') {
        this.$store.state.homeTitleX = modelHomeTitle
      }

      if (this.$store.state.homeDescriptionX == '') {
        this.$store.state.homeDescriptionX = modelHomeDescription
      }
      if (this.$store.state.homeKeywordsX == '') {
        this.$store.state.homeKeywordsX = modelHomeKeywords
      }

      if (this.$store.state.companyTitleX == '') {
        this.$store.state.companyTitleX = modelCompanyTitle
      }
      if (this.$store.state.companyDescriptionX == '') {
        this.$store.state.companyDescriptionX = modelCompanyDescription
      }
      if (this.$store.state.companyKeywordsX == '') {
        this.$store.state.companyKeywordsX = modelCompanyKeywords
      }

      if (this.$store.state.companyDetailsTitleX == '') {
        this.$store.state.companyDetailsTitleX = modelCompanyDetailsTitle
      }
      if (this.$store.state.companyDetailsDescriptionX == '') {
        this.$store.state.companyDetailsDescriptionX = modelCompanyDetailsDescription
      }
      if (this.$store.state.companyDetailsKeywordsX == '') {
        this.$store.state.companyDetailsKeywordsX = modelCompanyDetailsKeywords
      }

      if (this.$store.state.leadTitleX == '') {
        this.$store.state.leadTitleX = modelLeadTitle
      }
      if (this.$store.state.leadDescriptionX == '') {
        this.$store.state.leadDescriptionX = modelLeadDescription
      }
      if (this.$store.state.leadKeywordsX == '') {
        this.$store.state.leadKeywordsX = modelLeadKeywords
      }

      if (this.$store.state.leadDetailsTitleX == '') {
        this.$store.state.leadDetailsTitleX = modelLeadDetailsTitle
      }
      if (this.$store.state.leadDetailsDescriptionX == '') {
        this.$store.state.leadDetailsDescriptionX = modelLeadDetailsDescription
      }
      if (this.$store.state.leadDetailsKeywordsX == '') {
        this.$store.state.leadDetailsKeywordsX = modelLeadDetailsKeywords
      }

      if (this.$store.state.productTitleX == '') {
        this.$store.state.productTitleX = modelProductTitle
      }
      if (this.$store.state.productDescriptionX == '') {
        this.$store.state.productDescriptionX = modelProductDescription
      }
      if (this.$store.state.productKeywordsX == '') {
        this.$store.state.productKeywordsX = modelProductKeywords
      }

      if (this.$store.state.productDetailsTitleX == '') {
        this.$store.state.productDetailsTitleX = modelProductDetailsTitle
      }
      if (this.$store.state.productDetailsDescriptionX == '') {
        this.$store.state.productDetailsDescriptionX = modelProductDetailsDescription
      }
      if (this.$store.state.productDetailsKeywordsX == '') {
        this.$store.state.productDetailsKeywordsX = modelProductDetailsKeywords
      }
      // #endregion

      // #region If no Model then General
      if (this.$store.state.homeTitleX == '') {
        this.$store.state.homeTitleX = generalHomeTitle
      }
      if (this.$store.state.homeDescriptionX == '') {
        this.$store.state.homeDescriptionX = generalHomeDescription
      }
      if (this.$store.state.homeKeywordsX == '') {
        this.$store.state.homeKeywordsX = generalHomeKeywords
      }

      if (this.$store.state.companyTitleX == '') {
        this.$store.state.companyTitleX = generalCompanyTitle
      }
      if (this.$store.state.companyDescriptionX == '') {
        this.$store.state.companyDescriptionX = generalCompanyDescription
      }
      if (this.$store.state.companyKeywordsX == '') {
        this.$store.state.companyKeywordsX = generalCompanyKeywords
      }

      if (this.$store.state.companyDetailsTitleX == '') {
        this.$store.state.companyDetailsTitleX = generalCompanyDetailsTitle
      }
      if (this.$store.state.companyDetailsDescriptionX == '') {
        this.$store.state.companyDetailsDescriptionX = generalCompanyDetailsDescription
      }
      if (this.$store.state.companyDetailsKeywordsX == '') {
        this.$store.state.companyDetailsKeywordsX = generalCompanyDetailsKeywords
      }

      if (this.$store.state.leadTitleX == '') {
        this.$store.state.leadTitleX = generalLeadTitle
      }
      if (this.$store.state.leadDescriptionX == '') {
        this.$store.state.leadDescriptionX = generalLeadDescription
      }
      if (this.$store.state.leadKeywordsX == '') {
        this.$store.state.leadKeywordsX = generalLeadKeywords
      }

      if (this.$store.state.leadDetailsTitleX == '') {
        this.$store.state.leadDetailsTitleX = generalLeadDetailsTitle
      }
      if (this.$store.state.leadDetailsDescriptionX == '') {
        this.$store.state.leadDetailsDescriptionX = generalLeadDetailsDescription
      }
      if (this.$store.state.leadDetailsKeywordsX == '') {
        this.$store.state.leadDetailsKeywordsX = generalLeadDetailsKeywords
      }

      if (this.$store.state.productTitleX == '') {
        this.$store.state.productTitleX = generalProductTitle
      }
      if (this.$store.state.productDescriptionX == '') {
        this.$store.state.productDescriptionX = generalProductDescription
      }
      if (this.$store.state.productKeywordsX == '') {
        this.$store.state.productKeywordsX = generalProductKeywords
      }

      if (this.$store.state.productDetailsTitleX == '') {
        this.$store.state.productDetailsTitleX = generalProductDetailsTitle
      }
      if (this.$store.state.productDetailsDescriptionX == '') {
        this.$store.state.productDetailsDescriptionX = generalProductDetailsDescription
      }
      if (this.$store.state.productDetailsKeywordsX == '') {
        this.$store.state.productDetailsKeywordsX = generalProductDetailsKeywords
      }
      // #endregion

      this.applyReplaces()

      helper.appendTraffics(this.$store.state.allTrafficX, this.$store.state.specialTrafficX)

      // #region Scripts Region
      if (this.$store.state.jivoScriptX == undefined) {
        this.$store.state.jivoScriptX = ''
      }
      if (this.$store.state.googleAnalyticsCodeX == undefined) {
        this.$store.state.googleAnalyticsCodeX = ''
      }

      if (this.$store.state.jivoScriptX != '') {
        helper.appendJivoScript(this.$store.state.jivoScriptX, this.$store.state.langCodeX)
      }

      // if (this.$store.state.googleAnalyticsCodeX != "") {
      //   helper.appendGoogleAnalyticsScript(
      //     this.$store.state.googleAnalyticsCodeX,
      //     this.$store.state.langCodeX,
      //     this.$store.state.domainNameX,
      //     this.$store.state.currentUrlX,
      //     this.$store.state.homeTitleX
      //   );
      // }
      // #endregion

      this.isDone = true
    },
    applyReplaces() {
      var category1 = ''
      var category2 = ''

      if (this.$store.state.featuredCompaniesX.length > 0) {
        if (this.$store.state.featuredCompaniesX[0].products.length > 0) {
          if (this.$store.state.featuredCompaniesX[0].products[0].Localizations.length > 0) {
            this.firstProductName =
              this.$store.state.featuredCompaniesX[0].products[0].Localizations.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              ).length > 0
                ? this.$store.state.featuredCompaniesX[0].products[0].Localizations.filter(
                  (q) => q.LangCode == this.$store.state.langCodeX
                )[0].Name
                : this.$store.state.featuredCompaniesX[0].products[0].Localizations.filter(
                  (q) => q.LangCode != this.$store.state.langCodeX
                )[0].Name
          }
        }

        this.companyCountry =
          this.$store.state.featuredCompaniesX[0].country.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.featuredCompaniesX[0].country.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Name
            : this.$store.state.featuredCompaniesX[0].country.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Name

        this.firstCompanyName = this.$store.state.featuredCompaniesX[0].name

        this.firstCompanyDescription =
          this.$store.state.featuredCompaniesX[0].localizations.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.featuredCompaniesX[0].localizations.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].DescriptionContent
            : this.$store.state.featuredCompaniesX[0].localizations.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].DescriptionContent

        this.firstCompanyKeywords =
          this.$store.state.featuredCompaniesX[0].localizations.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.featuredCompaniesX[0].localizations.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Keywords
            : this.$store.state.featuredCompaniesX[0].localizations.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Keywords

        this.$store.state.featuredCompaniesX = this.$store.state.featuredCompaniesX.sort(
          () => 0.5 - Math.random()
        )

        this.randomCompanyName = this.$store.state.featuredCompaniesX[0].name

        this.randomCompanyDescription =
          this.$store.state.featuredCompaniesX[0].localizations.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.featuredCompaniesX[0].localizations.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].DescriptionContent
            : this.$store.state.featuredCompaniesX[0].localizations.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].DescriptionContent

        this.randomKeywords1 =
          this.$store.state.featuredCompaniesX[0].localizations.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.featuredCompaniesX[0].localizations.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Keywords
            : this.$store.state.featuredCompaniesX[0].localizations.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Keywords

        if (this.randomKeywords1 != null && this.randomKeywords1 != '') {
          this.randomKeywords1 = this.randomKeywords1.split(',')[0]
        }

        this.randomKeywords5 =
          this.$store.state.featuredCompaniesX[0].localizations.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.featuredCompaniesX[0].localizations.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Keywords
            : this.$store.state.featuredCompaniesX[0].localizations.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Keywords

        if (this.randomKeywords5 != null && this.randomKeywords5 != '') {
          this.randomKeywords5 = this.randomKeywords5.split(',').slice(0, 5).join(',')
        }

        var categories = this.$store.state.featuredCompaniesX[0].categories
        if (categories.length > 0) {
          this.firstCategoryName =
            categories[0].MainCategory.filter((q) => q.LangCode == this.$store.state.langCodeX)
              .length > 0
              ? categories[0].MainCategory.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              )[0].Name
              : categories[0].MainCategory.filter(
                (q) => q.LangCode != this.$store.state.langCodeX
              )[0].Name

          this.firstSubCategoryName =
            categories[0].SubCategory.filter((q) => q.LangCode == this.$store.state.langCodeX)
              .length > 0
              ? categories[0].SubCategory.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              )[0].Name
              : categories[0].SubCategory.filter(
                (q) => q.LangCode != this.$store.state.langCodeX
              )[0].Name

          categories = categories.sort(() => 0.5 - Math.random())

          this.randomCategoryName1 =
            categories[0].MainCategory.filter((q) => q.LangCode == this.$store.state.langCodeX)
              .length > 0
              ? categories[0].MainCategory.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              )[0].Name
              : categories[0].MainCategory.filter(
                (q) => q.LangCode != this.$store.state.langCodeX
              )[0].Name

          categories = categories.sort(() => 0.5 - Math.random())

          category1 =
            categories[0].MainCategory.filter((q) => q.LangCode == this.$store.state.langCodeX)
              .length > 0
              ? categories[0].MainCategory.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              )[0].Name
              : categories[0].MainCategory.filter(
                (q) => q.LangCode != this.$store.state.langCodeX
              )[0].Name
          if (categories.length > 1) {
            category2 =
              categories[1].MainCategory.filter((q) => q.LangCode == this.$store.state.langCodeX)
                .length > 0
                ? categories[1].MainCategory.filter(
                  (q) => q.LangCode == this.$store.state.langCodeX
                )[0].Name
                : categories[1].MainCategory.filter(
                  (q) => q.LangCode != this.$store.state.langCodeX
                )[0].Name
            this.randomCategoryName2 = `${category1},${category2}`
          } else {
            this.randomCategoryName2 = `${category1}`
          }
        }
      }

      if (this.$store.state.featuredLeadsX.length > 0) {
        this.leadCountry =
          this.$store.state.featuredLeadsX[0].country.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.featuredLeadsX[0].country.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Name
            : this.$store.state.featuredLeadsX[0].country.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Name

        this.leadTitle =
          this.$store.state.featuredLeadsX[0].localizations.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.featuredLeadsX[0].localizations.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Title
            : this.$store.state.featuredLeadsX[0].localizations.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Title

        this.firstLeadKeywords =
          this.$store.state.featuredLeadsX[0].localizations.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.featuredLeadsX[0].localizations.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Keywords
            : this.$store.state.featuredLeadsX[0].localizations.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Keywords

        this.$store.state.featuredLeadsX = this.$store.state.featuredLeadsX.sort(
          () => 0.5 - Math.random()
        )

        this.RandomLeadTitle =
          this.$store.state.featuredLeadsX[0].localizations.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.featuredLeadsX[0].localizations.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Title
            : this.$store.state.featuredLeadsX[0].localizations.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Title

        this.randomLeadKeywords1 =
          this.$store.state.featuredLeadsX[0].localizations.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.featuredLeadsX[0].localizations.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Keywords
            : this.$store.state.featuredLeadsX[0].localizations.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Keywords

        if (this.randomLeadKeywords1 != null && this.randomLeadKeywords1 != '') {
          this.randomLeadKeywords1 = this.randomLeadKeywords1.split(',')[0]
        }

        this.randomLeadKeywords5 =
          this.$store.state.featuredLeadsX[0].localizations.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.featuredLeadsX[0].localizations.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Keywords
            : this.$store.state.featuredLeadsX[0].localizations.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Keywords

        if (this.randomLeadKeywords5 != null && this.randomLeadKeywords5 != '') {
          this.randomLeadKeywords5 = this.randomLeadKeywords5.split(',').slice(0, 5).join(',')
        }
        var leadCategories = this.$store.state.featuredLeadsX[0].categories

        if (leadCategories.length > 0) {
          this.firstLeadCategoryName =
            leadCategories[0].MainCategory.filter((q) => q.LangCode == this.$store.state.langCodeX)
              .length > 0
              ? leadCategories[0].MainCategory.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              )[0].Name
              : leadCategories[0].MainCategory.filter(
                (q) => q.LangCode != this.$store.state.langCodeX
              )[0].Name

          this.firstLeadSubCategoryName =
            leadCategories[0].SubCategory.filter((q) => q.LangCode == this.$store.state.langCodeX)
              .length > 0
              ? leadCategories[0].SubCategory.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              )[0].Name
              : leadCategories[0].SubCategory.filter(
                (q) => q.LangCode != this.$store.state.langCodeX
              )[0].Name

          leadCategories = leadCategories.sort(() => 0.5 - Math.random())

          this.randomLeadCategoryName1 =
            leadCategories[0].MainCategory.filter((q) => q.LangCode == this.$store.state.langCodeX)
              .length > 0
              ? leadCategories[0].MainCategory.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              )[0].Name
              : leadCategories[0].MainCategory.filter(
                (q) => q.LangCode != this.$store.state.langCodeX
              )[0].Name

          leadCategories = leadCategories.sort(() => 0.5 - Math.random())

          category1 = ''
          category2 = ''
          category1 =
            leadCategories[0].MainCategory.filter((q) => q.LangCode == this.$store.state.langCodeX)
              .length > 0
              ? leadCategories[0].MainCategory.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              )[0].Name
              : leadCategories[0].MainCategory.filter(
                (q) => q.LangCode != this.$store.state.langCodeX
              )[0].Name
          if (leadCategories.length > 1) {
            category2 =
              leadCategories[1].MainCategory.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              ).length > 0
                ? leadCategories[1].MainCategory.filter(
                  (q) => q.LangCode == this.$store.state.langCodeX
                )[0].Name
                : leadCategories[1].MainCategory.filter(
                  (q) => q.LangCode != this.$store.state.langCodeX
                )[0].Name
            this.randomLeadCategoryName2 = `${category1},${category2}`
          } else {
            this.randomLeadCategoryName2 = `${category1}`
          }
        }
      }

      if (this.$store.state.domainCompanyCategoriesX.length > 0) {
        this.firstCategoryName =
          this.$store.state.domainCompanyCategoriesX[0].MainCategory.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.domainCompanyCategoriesX[0].MainCategory.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Name
            : this.$store.state.domainCompanyCategoriesX[0].MainCategory.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Name

        this.firstSubCategoryName =
          this.$store.state.domainCompanyCategoriesX[0].SubCategory.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.domainCompanyCategoriesX[0].SubCategory.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Name
            : this.$store.state.domainCompanyCategoriesX[0].SubCategory.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Name

        this.$store.state.domainCompanyCategoriesX =
          this.$store.state.domainCompanyCategoriesX.sort(() => 0.5 - Math.random())

        this.randomCategoryName1 =
          this.$store.state.domainCompanyCategoriesX[0].MainCategory.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.domainCompanyCategoriesX[0].MainCategory.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Name
            : this.$store.state.domainCompanyCategoriesX[0].MainCategory.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Name

        this.$store.state.domainCompanyCategoriesX =
          this.$store.state.domainCompanyCategoriesX.sort(() => 0.5 - Math.random())

        category1 = ''
        category2 = ''
        category1 =
          this.$store.state.domainCompanyCategoriesX[0].MainCategory.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.domainCompanyCategoriesX[0].MainCategory.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Name
            : this.$store.state.domainCompanyCategoriesX[0].MainCategory.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Name
        if (this.$store.state.domainCompanyCategoriesX.length > 1) {
          category2 =
            this.$store.state.domainCompanyCategoriesX[1].MainCategory.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            ).length > 0
              ? this.$store.state.domainCompanyCategoriesX[1].MainCategory.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              )[0].Name
              : this.$store.state.domainCompanyCategoriesX[1].MainCategory.filter(
                (q) => q.LangCode != this.$store.state.langCodeX
              )[0].Name
          this.randomCategoryName2 = `${category1},${category2}`
        } else {
          this.randomCategoryName2 = `${category1}`
        }
      }

      if (this.$store.state.domainLeadCategoriesX.length > 0) {
        this.firstLeadCategoryName =
          this.$store.state.domainLeadCategoriesX[0].MainCategory.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.domainLeadCategoriesX[0].MainCategory.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Name
            : this.$store.state.domainLeadCategoriesX[0].MainCategory.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Name

        this.firstLeadSubCategoryName =
          this.$store.state.domainLeadCategoriesX[0].SubCategory.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.domainLeadCategoriesX[0].SubCategory.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Name
            : this.$store.state.domainLeadCategoriesX[0].SubCategory.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            ).length > 0
              ? this.$store.state.domainLeadCategoriesX[0].SubCategory.filter(
                (q) => q.LangCode != this.$store.state.langCodeX
              )[0].Name
              : this.$store.state.domainLeadCategoriesX[0].MainCategory.filter(
                (q) => q.LangCode != this.$store.state.langCodeX
              )[0].Name

        this.$store.state.domainLeadCategoriesX = this.$store.state.domainLeadCategoriesX.sort(
          () => 0.5 - Math.random()
        )

        this.randomLeadCategoryName1 =
          this.$store.state.domainLeadCategoriesX[0].MainCategory.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.domainLeadCategoriesX[0].MainCategory.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Name
            : this.$store.state.domainLeadCategoriesX[0].MainCategory.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Name

        this.$store.state.domainLeadCategoriesX = this.$store.state.domainLeadCategoriesX.sort(
          () => 0.5 - Math.random()
        )

        category1 = ''
        category2 = ''
        category1 =
          this.$store.state.domainLeadCategoriesX[0].MainCategory.filter(
            (q) => q.LangCode == this.$store.state.langCodeX
          ).length > 0
            ? this.$store.state.domainLeadCategoriesX[0].MainCategory.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            )[0].Name
            : this.$store.state.domainLeadCategoriesX[0].MainCategory.filter(
              (q) => q.LangCode != this.$store.state.langCodeX
            )[0].Name
        if (this.$store.state.domainLeadCategoriesX.length > 1) {
          category2 =
            this.$store.state.domainLeadCategoriesX[1].MainCategory.filter(
              (q) => q.LangCode == this.$store.state.langCodeX
            ).length > 0
              ? this.$store.state.domainLeadCategoriesX[1].MainCategory.filter(
                (q) => q.LangCode == this.$store.state.langCodeX
              )[0].Name
              : this.$store.state.domainLeadCategoriesX[1].MainCategory.filter(
                (q) => q.LangCode != this.$store.state.langCodeX
              )[0].Name
          this.randomLeadCategoryName2 = `${category1},${category2}`
        } else {
          this.randomLeadCategoryName2 = `${category1}`
        }
      }

      if (this.companyCountry != '') {
        this.countryName = this.companyCountry
      } else {
        this.countryName = this.leadCountry
      }

      if (this.countryName == '') {
        this.countryName = 'Turkey'
      }

      this.$store.state.homeTitleX = this.toSeoFormat(this.$store.state.homeTitleX)

      this.$store.state.homeDescriptionX = this.toSeoFormat(this.$store.state.homeDescriptionX)
      this.$store.state.homeKeywordsX = this.toSeoFormat(this.$store.state.homeKeywordsX)

      this.$store.state.companyTitleX = this.toSeoFormat(this.$store.state.companyTitleX)
      this.$store.state.companyDescriptionX = this.toSeoFormat(
        this.$store.state.companyDescriptionX
      )
      this.$store.state.companyKeywordsX = this.toSeoFormat(this.$store.state.companyKeywordsX)

      this.$store.state.leadTitleX = this.toSeoFormat(this.$store.state.leadTitleX)
      this.$store.state.leadDescriptionX = this.toSeoFormat(this.$store.state.leadDescriptionX)
      this.$store.state.leadKeywordsX = this.toSeoFormat(this.$store.state.leadKeywordsX)

      this.$store.state.productTitleX = this.toSeoFormat(this.$store.state.productTitleX)
      this.$store.state.productDescriptionX = this.toSeoFormat(
        this.$store.state.productDescriptionX
      )
      this.$store.state.productKeywordsX = this.toSeoFormat(this.$store.state.productKeywordsX)
    },
    toSeoFormat(text) {
      if (this.$store.state.domainContentX.CompanyContent != undefined) {
        var selectedCategories = this.$store.state.domainContentX.CompanyContent.SelectedCategories
        var categoriesIdList = []
        selectedCategories.forEach((ele) => {
          var mainCategoryId = parseInt(ele.mainCategoryId)
          if (categoriesIdList.indexOf(mainCategoryId) != -1) {
            this.firstSubCategoryName = this.firstCategoryName
          } else {
            categoriesIdList.push(mainCategoryId)
          }
        })
      }

      try {
        text = text
          .replace('[DomainName]', this.domainName)
          .replace('[CompanyName]', this.firstCompanyName)
          .replace('[RandomCompanyName]', this.randomCompanyName)
          .replace('[CompanyDescription]', this.firstCompanyDescription)
          .replace('[RandomDescription]', this.randomCompanyDescription)
          .replace('[LeadTitle]', this.leadTitle)
          .replace('[RandomLeadTitle]', this.RandomLeadTitle)
          .replace('[CompanyKeywords]', this.firstCompanyKeywords)
          .replace('[RandomKeywords:1]', this.randomKeywords1)
          .replace('[RandomKeywords:5]', this.randomKeywords5)
          .replace('[LeadKeywords]', this.firstLeadKeywords)
          .replace('[RandomLeadKeywords:1]', this.randomLeadKeywords1)
          .replace('[RandomLeadKeywords:5]', this.randomLeadKeywords5)
          .replace('[Country]', this.countryName)
          .replace('[ProductName]', this.firstProductName)
          .replace('[Category]', this.firstCategoryName)
          .replace('[SubCategory]', this.firstSubCategoryName)
          .replace('[RandomCategory:1]', this.randomCategoryName1)
          .replace('[RandomCategory:2]', this.randomCategoryName2)
          .replace('[LeadCategory]', this.firstLeadCategoryName)
          .replace('[LeadSubCategory]', this.firstLeadSubCategoryName)
          .replace('[RandomLeadCategory:1]', this.randomLeadCategoryName1)
          .replace('[RandomLeadCategory:2]', this.randomLeadCategoryName2)

        if (this.$store.state.langCodeX == 'en') {
          text = text.replace('Turkey', 'Türkiye')
        }

        return text
      } catch (error) {
        return text
      }
    },
    clearSeoSettings() {
      this.$store.state.companyTitleX = ''
      this.$store.state.companyDescriptionX = ''
      this.$store.state.companyKeywordsX = []

      this.$store.state.companyDetailsTitleX = ''
      this.$store.state.companyDetailsDescriptionX = ''
      this.$store.state.companyDetailsKeywordsX = []

      this.$store.state.homeTitleX = ''
      this.$store.state.homeDescriptionX = ''
      this.$store.state.homeKeywordsX = []

      this.$store.state.leadTitleX = ''
      this.$store.state.leadDescriptionX = ''
      this.$store.state.leadKeywordsX = []

      this.$store.state.leadDetailsTitleX = ''
      this.$store.state.leadDetailsDescriptionX = ''
      this.$store.state.leadDetailsKeywordsX = []

      this.$store.state.productTitleX = ''
      this.$store.state.productDescriptionX = ''
      this.$store.state.productKeywordsX = []

      this.$store.state.productDetailsTitleX = ''
      this.$store.state.productDetailsDescriptionX = ''
      this.$store.state.productDetailsKeywordsX = []
    },
    async getMainCategories() {
      var temp = []
      await fetch(
        `https://data.mongodb-api.com/app/forienetwork_app-qolvc/endpoint/getMainCategories`,
        {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({})
        }
      )
        .then((res) => res.json())
        .then((data) => (temp = data))
        .catch((err) => console.log(err.message))

      this.$store.state.mainCategoriesX = temp
    },
    async registerDevice() {
      var localDeviceId = helper.generateGuid().toString()
      var result = []

      const myHeaders = new Headers()
      myHeaders.append('Accept', 'application/json')
      myHeaders.append('Content-Type', 'application/json')
      myHeaders.append('platform', 3)
      myHeaders.append('Public-WebAccessToken', this.$store.state.publicAccessTokenX)

      await fetch(
        `${this.$store.state.apiUrlX}/api/v2/Application/register?localDeviceId=${localDeviceId}&variableDeviceId=${localDeviceId}`,
        {
          method: 'post',
          headers: myHeaders,
          body: JSON.stringify({})
        }
      )
        .then((res) => res.json())
        .then((data) => (result = data))
        .catch((err) => console.log(err.message))

      if (result.resultText == 'Success') {
        this.$store.state.deviceIdX = result.responseObject.deviceId
        //Add to cookie
        helper.setCookie('fn_device_id', this.$store.state.deviceIdX, 1)
      }
    },
    async getUserDetails() {
      var accessToken = helper.getCookie('fn_access_token')
      var result = []

      await fetch(`${this.$store.state.apiUrlX}/api/v2/Account/getUserDetails`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Public-WebAccessToken': this.$store.state.publicAccessTokenX,
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then((res) => res.json())
        .then((data) => (result = data))
        .catch((err) => console.log(err.message))

      if (result.resultText == 'Success') {
        this.$store.state.userNameX = result.responseObject.name
        this.$store.state.userEmailX = result.responseObject.email
        this.$store.state.userPhoneX = result.responseObject.phone
        this.$store.state.userPasswordX = result.responseObject.password
        this.$store.state.userCountryCodeX = result.responseObject.region.countryCode
        this.$store.state.userLoggedInX = true
      } else {
        helper.setCookie('fn_access_token', '')
      }

      this.$store.state.isMountedDoneX = true
    },
    async logout() {
      var result = []
      var accessToken = helper.getCookie('fn_access_token')
      await fetch(`${this.$store.state.apiUrlX}/api/v2/Account/logout`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Public-WebAccessToken': this.$store.state.publicAccessTokenX,
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({})
      })
        .then((res) => res.json())
        .then((data) => (result = data))
        .catch((err) => console.log(err.message))

      if (result.resultText == 'Success') {
        this.$store.state.userLoggedInX = false
        helper.setCookie('fn_access_token', '')
        window.location.href = `http://auth.${this.$store.state.parentDomainX}/en/account/logout?returnUrl=${this.$store.state.currentUrlX}`
      } else {
        this.$store.state.userLoggedInX = false
        helper.setCookie('fn_access_token', '')
        window.location.href = `/${this.$store.state.langCodeX}`
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}

html * {
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none !important;
}

.breadcrumb {
  font-size: 13px;
}

.mark,
mark {
  color: #ed1c24 !important;
  font-weight: 700;
  background-color: transparent !important;
  padding: 1px !important;
}

.header-language {
  cursor: pointer;
}

.rfq-form .form-select {
  text-transform: capitalize;
}
</style>
