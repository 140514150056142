<template>
  <div class="container">
    <div class="row scale-div">
      <div class="col-6 pe-0">
        <div class="card">
          <div class="card-body">
            <img
              v-if="$store.state.headerLogoX == 'te.png'"
              src="../assets/img/te-logo.png"
              alt=""
            />
            <img v-else src="../assets/img/forie-logo.png" alt="" />
            <h1 class="mt-5">
              {{ $store.getters.getWordLocale('txt_tekrar_hos_geldiniz') }}
            </h1>
            <p class="mb-5">
              {{ $store.getters.getWordLocale('txt_eposta_sifre_ile_giris') }}
            </p>
            <div v-show="hasError" class="alert alert-danger">
              {{ $store.getters.getWordLocale('txt_eposta_veya_sifre_yanlis') }}
            </div>
            <div v-show="errorOccured" class="alert alert-danger">
              {{ $store.getters.getWordLocale('txt_islem_sirasinda_hata_olustu') }}
            </div>

            <div v-show="errorConnecting" class="alert alert-danger">
              {{ $store.getters.getWordLocale('txt_islem_sirasinda_hata_olustu') }}
            </div>

            <div v-show="getUserDetailsError" class="alert alert-danger">
              {{ $store.getters.getWordLocale('txt_islem_yaparken_hata_olustu') }}
            </div>
            <div id="form1">
              <div class="form-data">
                <strong
                  >{{ $store.getters.getWordLocale('txt_eposta') }}
                  <span class="text-danger">*</span></strong
                >
                <input
                  v-model="email"
                  type="text"
                  :class="{
                    'form-control': true,
                    'is-invalid': !isEmailValid && emailBlured,
                    'is-valid': isEmailValid && emailBlured
                  }"
                  :placeholder="$store.getters.getWordLocale('txt_eposta_adresi')"
                  @keyup="checkEmail($event)"
                  @focusout="checkEmail($event)"
                  @blur="emailBlured = true"
                />
                <div v-show="!isEmailValid" class="invalid-feedback">
                  {{ $store.getters.getWordLocale('txt_gecerli_eposta_adresi_giriniz') }}
                </div>

                <div class="mt-4">
                  <strong
                    >{{ $store.getters.getWordLocale('txt_sifre') }}
                    <span class="text-danger">*</span></strong
                  >
                </div>
                <input
                  v-model="password"
                  type="password"
                  :class="{
                    'form-control': true,
                    'is-invalid': !isPasswordValid && passwordBlured,
                    'is-valid': isPasswordValid && passwordBlured
                  }"
                  :placeholder="$store.getters.getWordLocale('txt_sifre')"
                  @keyup="checkPassword($event)"
                  @focusout="checkPassword($event)"
                  @blur="passwordBlured = true"
                />
                <div v-show="!isPasswordValid" class="invalid-feedback">
                  {{ $store.getters.getWordLocale('txt_sifre_giriniz') }}
                </div>

                <a
                  class="float-end mt-1"
                  data-bs-toggle="modal"
                  data-bs-target="#forgot-pw-modal"
                  href="javascript:;"
                  >{{ $store.getters.getWordLocale('txt_sifre_unuttum') }}</a
                >

                <button
                  ref="submitBtn"
                  class="w-100 mt-5 login-button"
                  @click.stop.prevent="submit"
                >
                  {{ $store.getters.getWordLocale('txt_giris_yap') }}
                  <span v-show="loggingIn"><i class="fa fa-spinner fa-spin"></i></span>
                </button>

                <a
                  :href="
                    'https://login.' +
                    $store.state.defaultDomainX +
                    '/' +
                    $store.state.langCodeX +
                    '/account/freejoin'
                  "
                  class="sign-up-btn text-center w-100 mt-5"
                >
                  {{ $store.getters.getWordLocale('txt_ucrestsiz_uye_ol') }}
                </a>
                <div
                  v-show="$store.state.userLoggedInX"
                  class="alert alert-success mt-4 text-center"
                >
                  <div class="mb-2">
                    <b
                      ><i class="fa fa-check-circle"></i>
                      {{ $store.getters.getWordLocale('txt_siteye_hos_geldiniz') }}!</b
                    >
                  </div>

                  <div>
                    {{ $store.getters.getWordLocale('txt_yonlendiriliyorsunuz') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 ps-0 right-image">
        <img src="https://login.turkishexporter.com.tr/storage/freejoin-bg.png" />
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    id="forgot-pw-modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="forgot-pw-modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div v-if="!sentSuccessfully" class="modal-body forgot-password">
          <h1>
            {{ $store.getters.getWordLocale('txt_sifremi_unuttum') }}
          </h1>
          <p class="mb-4">
            {{ $store.getters.getWordLocale('txt_sifremi_unuttum_aciklama') }}
          </p>

          <strong
            >{{ $store.getters.getWordLocale('txt_eposta') }}
            <span class="text-danger">*</span></strong
          >
          <input
            v-model="forgotPwEmail"
            type="text"
            :class="{
              'form-control': true,
              'is-invalid': !isForgotPwEmailValid && forgotPwBlured,
              'is-valid': isForgotPwEmailValid && forgotPwBlured
            }"
            :placeholder="$store.getters.getWordLocale('txt_eposta')"
            @keyup="checkForgotPwEmail($event)"
            @focusout="checkForgotPwEmail($event)"
            @blur="forgotPwBlured = true"
          />
          <div v-show="!isForgotPwEmailValid" class="invalid-feedback">
            {{ $store.getters.getWordLocale('txt_gecerli_eposta_adresi_giriniz') }}
          </div>

          <div v-show="emailNotFound" class="alert alert-danger mt-2">
            <i class="fa fa-info-circle"></i>
            {{ $store.getters.getWordLocale('txt_kayitli_eposta_bulunamadi') }}
          </div>
        </div>
        <div v-else>
          <div class="alert alert-success mt-5 ms-2 me-2">
            <h1>
              {{ $store.getters.getWordLocale('txt_sifremi_unuttum') }}
            </h1>
            <i class="fa fa-check-circle"></i>
            {{ $store.getters.getWordLocale('txt_resetleme_eposta_gonderildi') }}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
            {{ $store.getters.getWordLocale('txt_kapat') }}
          </button>
          <button
            v-if="!sentSuccessfully"
            ref="resetBtn"
            type="button"
            class="btn btn-dark"
            @click="resetEmail"
          >
            {{ $store.getters.getWordLocale('txt_gonder') }}
            <span v-show="sendingEmail"><i class="fa fa-spinner fa-spin"></i></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import helper from './helper'

export default {
  setup() {
    const siteData = reactive({
      title: 'Login',
      description: '',
      keywords: ''
    })

    useHead({
      // Can be static or computed
      title: computed(() => `${siteData.title}`),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        }
      ]
    })
  },
  data() {
    return {
      sentSuccessfully: false,
      emailNotFound: false,
      sendingEmail: false,
      forgotPwBlured: false,
      isForgotPwEmailValid: true,
      forgotPwEmail: '',
      isEmailValid: true,
      isPasswordValid: true,
      errorConnecting: false,
      getUserDetailsError: false,
      loggingIn: false,
      email: '',
      emailBlured: false,
      valid: false,
      password: '',
      passwordBlured: false,
      hasError: false,
      errorOccured: false
    }
  },
  mounted() {
    if (this.$store.state.userLoggedInX) {
      this.$router.push(`/${this.$store.state.langCodeX}`)
    }
  },
  methods: {
    async resetEmail() {
      this.forgotPwBlured = true
      this.emailNotFound = false

      //this.checkForgotPwEmail();
      if (this.isForgotPwEmailValid == true && !this.sendingEmail) {
        this.sendingEmail = true
        var result = []

        await fetch(
          `${this.$store.state.apiUrlX}/api/v2/Account/sendResetPasswordEmail?email=${this.forgotPwEmail}`,
          {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Public-WebAccessToken': this.$store.state.publicAccessTokenX,
              lang: this.$store.state.langCodeX
            },
            body: JSON.stringify({})
          }
        )
          .then((res) => res.json())
          .then((data) => (result = data))
          .catch((err) => console.log(err.message))

        this.sendingEmail = false
        if (result != '') {
          if (result.resultText != 'Success') {
            this.emailNotFound = true
          } else {
            this.emailNotFound = false
            this.sentSuccessfully = true
          }
        }
        console.log(result)
      }
    },
    validate: function () {
      this.emailBlured = true
      this.passwordBlured = true
      if (this.validEmail(this.email) && this.validPassword(this.password)) {
        this.valid = true
      }
    },
    checkEmail(e) {
      this.isEmailValid = this.validEmail(this.email)

      if (e.keyCode == 13) {
        const elem = this.$refs.submitBtn
        elem.click()
      }
    },
    checkForgotPwEmail(e) {
      this.isForgotPwEmailValid = this.validEmail(this.forgotPwEmail)

      if (e.keyCode == 13) {
        const elem = this.$refs.resetBtn
        elem.click()
      }
    },
    checkPassword(e) {
      this.isPasswordValid = this.validPassword(this.password)

      if (e.keyCode == 13) {
        const elem = this.$refs.submitBtn
        elem.click()
      }
    },
    validEmail: function (email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/
      if (re.test(email.toLowerCase())) {
        return true
      }
    },
    validPassword: function (password) {
      if (password.length > 0) {
        return true
      }
    },
    submit: function () {
      this.hasError = false
      this.errorOccured = false
      this.errorConnecting = false

      this.validate()
      this.isEmailValid = this.validEmail(this.email)
      this.isPasswordValid = this.validPassword(this.password)

      if (this.valid) {
        this.loggingIn = true
        this.login()
      }
    },
    async login() {
      if (this.$store.state.deviceIdX != null) {
        var result = []

        await fetch(
          `${this.$store.state.apiUrlX}/api/v2/Account/login?email=${this.email}&password=${this.password}&deviceId=${this.$store.state.deviceIdX}&test=false`,
          {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Public-WebAccessToken': this.$store.state.publicAccessTokenX
            },
            body: JSON.stringify({})
          }
        )
          .then((res) => res.json())
          .then((data) => (result = data))
          .catch((err) => console.log(err.message))

        this.loggingIn = false
        if (result == '') {
          this.errorConnecting = true
        } else {
          if (result.resultText == 'Success') {
            var accessToken = result.responseObject.accessToken
            //Add to cookie
            helper.setCookie('fn_access_token', accessToken, 1)
            this.$store.state.apiAccessTokenX = accessToken

            //GetUserDetails
            this.getUserDetails()
          } else {
            if (result.resultText == 'warning') {
              this.hasError = true
            } else {
              this.errorOccured = true
            }
          }
        }
      }
    },
    async getUserDetails() {
      var accessToken = helper.getCookie('fn_access_token')
      this.$store.state.apiAccessTokenX = accessToken

      var result = []

      await fetch(`${this.$store.state.apiUrlX}/api/v2/Account/getUserDetails`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Public-WebAccessToken': this.$store.state.publicAccessTokenX,
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then((res) => res.json())
        .then((data) => (result = data))
        .catch((err) => console.log(err.message))

      this.loggingIn = false
      if (result.resultText == 'Success') {
        this.$store.state.userNameX = result.responseObject.name
        this.$store.state.userEmailX = result.responseObject.email
        this.$store.state.userPhoneX = result.responseObject.phone

        this.$store.state.userLoggedInX = true
        setTimeout(() => {
          window.location.href = this.$store.state.currentUrlX
        }, 1000)
      } else {
        this.getUserDetailsError = true
      }
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 24px;
  color: #241f20;
  font-weight: bold;
}
input {
  height: 50px;
  border: solid 1px #dfe5ee;
  width: 100%;
  padding: 15px 16px;
  outline: none !important;
}

.card {
  min-height: 100%;
  border-radius: 0;
}

right-image img {
  height: 100%;
  width: 100%;
}

.system-name {
  color: teal;
}

.login-button {
  width: 100%;
  font-size: 14px;
  background-color: #2a2a2a !important;
  border: none;
  font-weight: 600;
  color: #fff;
  outline: none !important;
  padding: 14px 0px;
  transition:
    background-color 0.5s,
    color 0.5s;
  cursor: pointer;
}

.login-button:hover {
  background-color: #dfe5ee !important;
  color: #565656 !important;
}

.scale-div {
  transform: scale(0.9);
}

.forgot-password h1 {
  font-size: 24px;
  color: #241f20;
  font-weight: bold;
}

.forgot-password p {
  font-size: 13px;
  margin-top: 13px;
  margin-bottom: 30px;
  color: #9399a2;
}

button {
  border-radius: 0;
}

.modal-content {
  border-radius: 0;
}

.sign-up-btn {
  font-size: 14px;
  border: solid 1px #dfe5ee;
  text-decoration: none !important;
  font-weight: 600;
  display: block;
  color: #00245d;
  outline: none !important;
  padding: 14px 0px;
  cursor: pointer;
  transition: backgrou;
}

.sign-up-btn:hover {
  background-color: #b7c4c9;
  color: #121212;
}
</style>
