import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import PrimeVue from 'primevue/config'
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primeicons/primeicons.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab)
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(far)
import { dom } from '@fortawesome/fontawesome-svg-core'
dom.watch()
import store from './store'
import { createHead } from '@vueuse/head'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

const head = createHead()

createApp(App)
  .use(router)
  .use(PrimeVue)
  .use(store)
  .use(head)
  .use(VueSweetalert2)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')

// TODO : Bu kod tarayıcından vue projesi üzerinde olan bir linke tıklandığında turkishexporter.com.tr ye yönlendirme yapmaktadır
router.beforeEach((to, from, next) => {
  const path = window.location.pathname
  const pathType = path.split('/')
  const langCode = pathType[1]
  const mainSegment = pathType[2]
  const lastPath = pathType[pathType.length - 1]

  /* if (mainSegment === 'lead') {
    window.location.href = `http://www.turkishexporter.com.tr/${langCode}/imports-exports/${lastPath}`
  } else if */ if (mainSegment === 'company') {
    window.location.href = `http://www.turkishexporter.com.tr/${langCode}/companies/${lastPath}`
  } else {
    next()
  }
})
