<template>
  <component :is="optionComponent" />
</template>

<script>
import { defineAsyncComponent } from 'vue'
const Theme3 = defineAsyncComponent(() => import('../components/theme3/Contact.vue'))

const Theme5 = defineAsyncComponent(() => import('../components/theme5/Contact.vue'))

import PagePropertiesMixin from '../mixins/pagePropertiesMixin'
import { mapState } from 'vuex'
import helper from './helper.js'
export default {
  mixins: [PagePropertiesMixin],
  data() {
    return {
      pageTitle: '',
      pageMeta: []
    }
  },
  computed: mapState({
    detailsTitleX: (state) => state.detailsTitleX,
    optionComponent() {
      if (this.$store.state.modelCodeX == 'single-company') {
        return Theme3
      }

      if (this.$store.state.modelCodeX == 'single-company-1') {
        return Theme5
      }
    }
  }),
  watch: {
    detailsTitleX() {
      this.pageTitle = this.$store.state.detailsTitleX
      this.pageMeta = [
        {
          name: 'description',
          content: this.$store.state.detailsDescriptionX
        },
        {
          name: 'keywords',
          content: this.$store.state.detailsKeywordsX
        }
      ]
    }
  },
  mounted() {
    this.pageTitle = this.$store.state.detailsTitleX
    if (this.$store.state.googleAnalyticsCodeX != '') {
      helper.appendGoogleAnalyticsScript(
        this.$store.state.googleAnalyticsCodeX,
        this.$store.state.langCodeX,
        this.$store.state.domainNameX,
        this.$store.state.currentUrlX,
        this.$store.state.homeTitleX,
        this.$store.state.membershipNameX,
        this.$store.state.companyNameX,
        this.$store.state.singleCompanySecondaryIdX,
        this.$store.state.isVipX
      )
    }
  }
}
</script>
