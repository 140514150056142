<template>
  <component :is="optionComponent" :place-holder="placeHolder" />
</template>

<script>
import { defineAsyncComponent } from 'vue'
const Theme1 = defineAsyncComponent(() => import('../components/theme1/Header.vue'))

const Theme2 = defineAsyncComponent(() => import('../components/theme2/Header.vue'))

const Theme3 = defineAsyncComponent(() => import('../components/theme3/Header.vue'))

const Theme4 = defineAsyncComponent(() => import('../components/theme4/Header.vue'))

const Theme5 = defineAsyncComponent(() => import('../components/theme5/Header.vue'))

const Theme6 = defineAsyncComponent(() => import('../components/theme6/Header.vue'))

export default {
  components: {},
  data() {
    return {
      placeHolder: ''
    }
  },
  computed: {
    optionComponent() {
      if (this.$store.state.modelCodeX == 'default') {
        return Theme1
      }

      if (this.$store.state.modelCodeX == 'ek-web') {
        return Theme2
      }

      if (this.$store.state.modelCodeX == 'single-company') {
        return Theme3
      }

      if (this.$store.state.modelCodeX == 'multi-company') {
        return Theme4
      }

      if (this.$store.state.modelCodeX == 'single-company-1') {
        return Theme5
      }

      if (this.$store.state.modelCodeX == 'multi-company-1') {
        return Theme6
      }

      return ''
    }
  },
  watch: {
    $route() {
      var host = window.location.href
      if (host.indexOf('/companies') !== -1) {
        this.placeHolder = 'txt_firma_arayin'
      } else if (host.indexOf('/leads') !== -1) {
        this.placeHolder = 'txt_talep_arayin'
      } else if (host.indexOf('/products') !== -1) {
        this.placeHolder = 'txt_urun_arayin'
      } else {
        this.placeHolder = 'txt_firma_talep_urun_arayin'
      }
    }
  },
  methods: {}
}
</script>

<style></style>
